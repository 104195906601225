import React from 'react';
import {useRecoilState, useRecoilValue} from 'recoil';

import {gridVisibleAtom, hasUnsavedItemChangesAtom, lifeHorizontalPosAtom, zoomedOutModeAtom} from '../state/atoms.ui';
import RoundIconButton from './commons/RoundIconButton';
import SearchField from './SearchField';
import StreamsFilter from './StreamsFilter';
import useActionAddNewItem from '../state/actions/addNewItem';

import {StyledOptionsBar} from './_styled';

const OptionsBar = () => {
  const hasUnsavedChanges = useRecoilValue(hasUnsavedItemChangesAtom);
  const horizontalPos = useRecoilValue(lifeHorizontalPosAtom);
  const [zoomedOutMode, setZoomedOutMode] = useRecoilState(zoomedOutModeAtom);
  const [gridVisible, setGridVisible] = useRecoilState(gridVisibleAtom);
  const actAddNewItem = useActionAddNewItem();

  return (
    <StyledOptionsBar>
      <RoundIconButton
        title="Show Year Lines"
        className="icon-table"
        onClick={() => setGridVisible(!gridVisible)}
        active={gridVisible}
      />

      <RoundIconButton
        title={zoomedOutMode ? 'Reset Zoom' : 'Zoom out'}
        className={zoomedOutMode ? 'icon-resize-full' : 'icon-resize-small'}
        onClick={() => setZoomedOutMode(!zoomedOutMode)}
      />

      <StreamsFilter />

      <SearchField />

      <RoundIconButton title="New Item" onClick={onNewItem} className="icon-plus" disabled={hasUnsavedChanges} />
    </StyledOptionsBar>
  );

  function onNewItem() {
    actAddNewItem(horizontalPos);
  }
};

export default OptionsBar;
