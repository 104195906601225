import {toTsSeconds} from '../timeUtil';
import StreamDescription from '../../domain/StreamDescription';
import PersistedItem from './PersistedItem';

/**
 * the persisted version of "Life".
 * timestamp (in seconds) of your birth
 * has flat list of streams.
 * has flat list of items.
 */
export default interface PersistedLife {
  birth: number;
  photoAlbumId: string;
  streams: StreamDescription[];
  items: PersistedItem[];
}

export const getDefaultPersistedLifeToStartWith = () => ({
  birth: 946688400, // roughly 1.1.2000
  streams: [{id: 'first-stream', title: 'Your first stream', color: '#ff0000'}],
  items: [
    {
      id: 'first-item',
      startDate: toTsSeconds(new Date()),
      title: 'First item',
      description: "This is your first item. Don't forget to set your date of birth in the settings...",
      stream: 'first-stream'
    }
  ]
});
