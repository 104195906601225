import React, {useState} from 'react';

import PhotoItem from '../../domain/PhotoItem';
import Spinner from './Spinner';
import useLoadPhotoItems from './useLoadPhotoItems';

import {StyledPhotoDisplay, StyledPhotoDisplayInner, StyledPhotoItem, StyledPhotoItemPlaceholder} from './_styled';

interface PhotoDisplayTypes {
  accessToken: string;
  photoIds: string[];
  onPhotoRemove?: (photoId: string) => void;
}

const PhotoDisplay = ({accessToken, photoIds, onPhotoRemove}: PhotoDisplayTypes) => {
  const [photosLoading, setPhotosLoading] = useState<boolean>(false);
  const [photos, setPhotos] = useState<PhotoItem[]>([]);

  useLoadPhotoItems(
    accessToken,
    photoIds,
    () => {
      setPhotosLoading(true);
    },
    (items) => {
      setPhotosLoading(false);
      setPhotos(items);
    }
  );

  return (
    <StyledPhotoDisplay>
      <StyledPhotoDisplayInner>
        {!photosLoading &&
          photos.map((p: PhotoItem) => (
            <StyledPhotoItem key={`photo:${p.id}`} onClick={() => onItemClicked(p)} $hoverable={!!onPhotoRemove}>
              <img src={`${p.baseUrl}=h160-w160-c`} alt={`${p.id}`} />
            </StyledPhotoItem>
          ))}

        {photosLoading && photoIds.map((id: string) => <StyledPhotoItemPlaceholder key={`photo-placeholder:${id}`} />)}
      </StyledPhotoDisplayInner>

      <div>
        {photoIds.length < photoIds.length && (
          <>
            Displaying {photoIds.length} of {photoIds.length}
          </>
        )}
      </div>
    </StyledPhotoDisplay>
  );

  function onItemClicked(photoItem: PhotoItem) {
    if (onPhotoRemove) {
      onPhotoRemove(photoItem.id);
    }
  }
};

export default PhotoDisplay;

interface PhotoDisplaySkeletonTypes {
  count: number;
  withSpinners?: boolean;
}

export const PhotoDisplaySkeleton = ({count, withSpinners}: PhotoDisplaySkeletonTypes) => {
  return (
    <StyledPhotoDisplay>
      <StyledPhotoDisplayInner>
        {new Array(count).fill(true).map((d: boolean, index: number) => (
          <StyledPhotoItemPlaceholder key={`photo-skeleton:${index}`}>
            <Spinner />
          </StyledPhotoItemPlaceholder>
        ))}
      </StyledPhotoDisplayInner>
    </StyledPhotoDisplay>
  );
};
