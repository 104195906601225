import {useEffect} from 'react';
import {isBefore, sub} from 'date-fns';

import GooglePhotoItemService from '../../data/service/impl/google/GooglePhotoItemService';
import PhotoItem from '../../domain/PhotoItem';
import CachedPhotoItem from '../../domain/CachedPhotoItem';
import {useRecoilState} from 'recoil';
import {photoItemCacheAtom} from '../../state/atoms';

/**
 *
 */
export default function useLoadPhotoItems(
  accessToken: string,
  photoIds: string[] | undefined,
  onStartLoading: () => void,
  onLoaded: (p: PhotoItem[]) => void
) {
  const [photoItemCache, setPhotoItemCache] = useRecoilState(photoItemCacheAtom);

  useEffect(() => {
    let componentStillMounted = true;

    if (!photoIds || photoIds.length < 1) {
      onLoaded([]);
      return;
    }

    const [validFromCache, idsToLoad] = getItemsFromCache(photoIds, photoItemCache);

    if (idsToLoad.length < 1) {
      onLoaded(validFromCache);
      return;
    }

    onStartLoading();

    GooglePhotoItemService.getItemsForIds(accessToken, idsToLoad)
      .then((items) => {
        if (componentStillMounted) {
          const newCache = putItemsToCache(items, photoItemCache);
          setPhotoItemCache(newCache);
          onLoaded([...items, ...validFromCache]);
        }
      })
      .catch((err) => console.error(err));

    return () => {
      // useEffect cleanup
      componentStillMounted = false;
    };
  }, [photoIds, accessToken]);
}

function getItemsFromCache(
  photoIds: string[],
  photoItemCache: Record<string, CachedPhotoItem>
): [PhotoItem[], string[]] {
  const mustBePutInCacheBefore = sub(new Date(), {minutes: 59});

  const validFromCache: PhotoItem[] = [];
  const idsToLoad: string[] = [];

  photoIds.forEach((photoId) => {
    const fromCache = photoItemCache[photoId];
    if (fromCache && isBefore(mustBePutInCacheBefore, new Date(fromCache.putInCache))) {
      validFromCache.push(fromCache);
    } else {
      idsToLoad.push(photoId);
    }
  });

  return [validFromCache, idsToLoad];
}

function putItemsToCache(
  items: PhotoItem[],
  currentCache: Record<string, CachedPhotoItem>
): Record<string, CachedPhotoItem> {
  const newCache: Record<string, CachedPhotoItem> = {...currentCache};
  items.forEach((item) => {
    newCache[item.id] = {
      ...item,
      putInCache: Date.now()
    };
  });
  return newCache;
}
