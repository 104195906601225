import {atom} from 'recoil';

import Stream from '../domain/Stream';
import Album from '../domain/Album';
import LifeItem from '../domain/LifeItem';
import CachedPhotoItem from '../domain/CachedPhotoItem';

/**
 * Will contain all streams with their respective items.
 * Please check also "LifePersistorObserver" which listens to this atom and stores these streams to persistent storage, if they change
 */
export const streamsAtom = atom<Stream[] | undefined>({
  key: 'streams',
  default: undefined
});

/**
 * Will contain the date of birth
 */
export const birthAtom = atom<Date>({
  key: 'birth',
  default: undefined
});

export const photoAlbumIdAtom = atom<string>({
  key: 'photoAlbumId',
  default: ''
});

/**
 * Holds the trashed items (non persistent) as state in memory, so that the user can recover them
 */
export const trashedItemsAtom = atom<LifeItem[]>({
  key: 'trashedItems',
  default: []
});

/**
 * List of google photo albums
 */
export const albumListAtom = atom<Album[] | undefined>({
  key: 'albumList',
  default: undefined
});

/**
 *
 */
export const searchFieldTriggerOpenAtom = atom<boolean>({
  key: 'searchFieldTriggerOpen',
  default: false
});

/**
 *
 */
export const photoItemCacheAtom = atom<Record<string, CachedPhotoItem>>({
  key: 'photoItemCache',
  default: {}
});
