import React from 'react';
import {useRecoilValue} from 'recoil';

import Button from '../commons/Button';
import {formatDateNumeric} from '../../data/timeUtil';
import {getStreamsOrThrow} from '../../state/selectors';

import {convertToPersisted} from '../../data/service/lifeService';
import {birthAtom, photoAlbumIdAtom} from '../../state/atoms';

const LifeExportButton = () => {
  const streams = useRecoilValue(getStreamsOrThrow);
  const birth = useRecoilValue(birthAtom);
  const photoAlbumId = useRecoilValue(photoAlbumIdAtom);

  return <Button onClick={onExportButtonClick}>Export your life as json File</Button>;

  function onExportButtonClick() {
    const fileContent = JSON.stringify(convertToPersisted(streams, birth, photoAlbumId), null, 0);
    const blob = new Blob([fileContent], {
      type: 'text/plain;charset=utf-8'
    });
    const aElement = document.createElement('a');
    aElement.download = `myLife_export_${formatDateNumeric(new Date())}.json`;
    aElement.href = URL.createObjectURL(blob);
    aElement.click();
  }
};

export default LifeExportButton;
