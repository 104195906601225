import {isValid, parseISO} from 'date-fns';
import PhotoItemService from '../../PhotoItemService';
import PhotoItem from '../../../../domain/PhotoItem';
import {handleFetchResponseErrors} from '../../fetchUtils';

const googlePhotoItemService: PhotoItemService = {
  getItemsForIds
};

export default googlePhotoItemService;

async function getItemsForIds(accessToken: string, ids: string[]): Promise<PhotoItem[]> {
  const params = new URLSearchParams(ids.map((i) => ['mediaItemIds', i]));

  // https://developers.google.com/photos/library/reference/rest/v1/mediaItems/batchGet
  // TODO:   this currently (2025-02) can return all media items. with the changes in google photo api, by ca May 2025, the app might only be able to access "own" photos. need to check this
  // TODO:   what we still don´t know is, do we need to create a "own" (my-life) album in google photos and upload all picked photos to that album after picking?
  const res = await fetch(`https://photoslibrary.googleapis.com/v1/mediaItems:batchGet?${params.toString()}`, {
    method: 'GET',
    headers: new Headers({
      Authorization: 'Bearer ' + accessToken,
      'Content-type': 'application/json'
    })
  });

  await handleFetchResponseErrors(res);

  const body = await res.json();

  return body.mediaItemResults
    .map((miR: any): PhotoItem | null => {
      if (miR.mediaItem) {
        return mapMediaItemToPhotoItem(miR.mediaItem);
      } else if (miR.status) {
        console.warn('Could not load mediaItem.', miR.status);
        return null;
      } else {
        console.error('Invalid response!', miR);
        return null;
      }
    })
    .filter((mi: PhotoItem | null) => !!mi);
}

/**
 * https://developers.google.com/photos/library/reference/rest/v1/mediaItems#MediaItem
 */
function mapMediaItemToPhotoItem(googleMediaItem: any): PhotoItem | null {
  const mimeType = googleMediaItem.mimeType;

  if (mimeType.substring(0, 5) !== 'image') {
    console.warn(`skipping mediaItem with mimeType=${mimeType}`);
    return null;
  }

  return {
    id: googleMediaItem.id,
    baseUrl: googleMediaItem.baseUrl,
    date: parseGoogleDate(googleMediaItem.mediaMetadata.creationTime)
  };
}

/**
 *
 * @param gDate is in format  2018-06-28T14:17:34Z
 * @returns {number}
 */
function parseGoogleDate(gDate: string): number {
  if (!gDate) {
    return 0;
  }

  const asDate = parseISO(gDate);
  if (isValid(asDate)) {
    return Math.floor(asDate.getTime() / 1000);
  } else {
    return 0;
  }
}
