import React, {useRef} from 'react';
import {useRecoilState, useRecoilValue} from 'recoil';
import {ThemeProvider} from 'styled-components';

import rightMouseDragRefCb, {RightMouseDragRef} from './rightMouseDragRefCb';
import SpotlightStream from './stream/SpotlightStream';
import MonthsTimeLine from './MonthsTimeLine';
import MonthsGrid from './MonthsGrid';
import JumpToYearField from './JumpToYearField';
import {getItemsInYearFlatSelector} from '../../state/selectors';
import {gridVisibleAtom, jumpToYearInputVisibleAtom} from '../../state/atoms.ui';
import useActionSelectItem from '../../state/actions/selectItem';
import useActionSetSpotlightYear from '../../state/actions/setSpotlightYear';
import {useCalculateSpotlightStreamsWrapperWidth} from './spotlightLayouter';

import {SpotlightYearMarker, StyledLife, StyledStreamsWrapper} from './_styled';

interface SpotlightYearTypes {
  year: number;
}

const SpotlightYear = ({year}: SpotlightYearTypes) => {
  const gridVisible = useRecoilValue(gridVisibleAtom);
  const itemsInYearsFlat = useRecoilValue(getItemsInYearFlatSelector(year));
  const actSelectItem = useActionSelectItem();
  const streamsWrapperWidth = useCalculateSpotlightStreamsWrapperWidth(year);
  const [jumpToYrVisible, setJumpToYrVisible] = useRecoilState(jumpToYearInputVisibleAtom);
  const actSetSpotlightYear = useActionSetSpotlightYear();

  const dragRef = useRef() as React.MutableRefObject<RightMouseDragRef>;

  return (
    <ThemeProvider theme={{zoomedOut: false}}>
      <StyledLife ref={lifeRefCb}>
        {jumpToYrVisible && <JumpToYearField onYearSelected={onJumpToYearSelected} onCancel={onJumpToYearCanceled} />}

        <MonthsGrid year={year} visible={gridVisible} />
        <MonthsTimeLine year={year} />

        <StyledStreamsWrapper style={{width: `${streamsWrapperWidth}px`}} x-component-name="StyledStreamsWrapper">
          <SpotlightYearMarker>
            <span>{year}</span>
            {!itemsInYearsFlat?.length && <div>No items</div>}
          </SpotlightYearMarker>

          {itemsInYearsFlat?.length > 0 && (
            <SpotlightStream
              key={`stream:spotlight:${year}`}
              year={year}
              itemsFlat={itemsInYearsFlat}
              onDeselectItem={() => actSelectItem(undefined)}
              onSelectItem={actSelectItem}
            />
          )}
        </StyledStreamsWrapper>

        <MonthsTimeLine year={year} />
      </StyledLife>
    </ThemeProvider>
  );

  function lifeRefCb(element: HTMLDivElement | null) {
    rightMouseDragRefCb(element, dragRef);
  }

  function onJumpToYearSelected(year: number) {
    setJumpToYrVisible(false);
    actSetSpotlightYear(year);
  }

  function onJumpToYearCanceled() {
    setJumpToYrVisible(false);
  }
};

export default SpotlightYear;
