import {createServer, Request, Server} from 'miragejs';
import {addSeconds} from 'date-fns';

import envConfig from '../../envConfig';

const driveFileId = '1bve3P3yb_6lV_m2pSM9od2rkIrD020xp';

if (envConfig.interceptHTTP) {
  console.info('using mirage js to intercept HTTP calls and return dummy data!');

  createServer({
    routes() {
      googlePhotoRoutes(this);
      googleDriveRoutes(this);
      googleAuthRoutes(this);
    }
  });
}

function googlePhotoRoutes(s: Server) {
  s.get(`https://photoslibrary.googleapis.com/v1/albums`, () => {
    return {
      albums: [
        {
          id: 'ACPp6nOWPV3G959upm5peBsp_lo_Ff7ef3o9JiMymduF-a4gVqE028dFYM1hFOOjUdz3m2d3eucE',
          title: 'Binntal 2024.3',
          productUrl:
            'https://photos.google.com/lr/album/ACPp6nOWPV3G959upm5peBsp_lo_Ff7ef3o9JiMymduF-a4gVqE028dFYM1hFOOjUdz3m2d3eucE',
          mediaItemsCount: '29',
          coverPhotoBaseUrl:
            'https://lh3.googleusercontent.com/lr/AAJ1LKfFOvw_UKoU2TMfzXqS5xagpgJuiE-1EF4wAFD8yny-gLHewaGqFbDVR0z5xYeoAM4U76J6aZ-M74x18867OdflEN8bd_BCXigG4V9dHIZshVSikNHEQBPikZp-vImMgHXvxbmaGdcV0Ft2NOcXblmkKpK7u6UZB2U71V-yI_OsKcNdghfgNIpuu3FcB1DKIrDzsUH7x1tcrxYn1K7d5xyatCKMMhkp0VpqQnq9KwDFfX1aTWIADlctlmNPQGhH46P0W26z6UKL22ni-qoffwC2rSkXFkTnBH36CddMiVRGwgNF-6yRlkkTxMJjufq03Hha6MrXYO0YYUJirCe5TSrPDKz4BDuBDHBks8UFjEEO3a76Rn9Lo6pp457LCTGHPpM_5bTlNPuVwf31k_DuW4ko-eJMDnevQPes8cNIXg5wFxeOZIz0gTMcnMJJRjOGGcpwkaNvMpGAwz9eluyXdqw8pUvLwzsN70UdM8xL26ZRXCLqj5XbKO8a6j6alFOO6OWviLaKc3Y8Gl_RvyGO0BveDU44_ucfSwF8uApDXY0Op9SBb9O3kfjAx5El6OKa-sdXWqQ7TYFB0kruv9rX0SLB8W4InNxTrqSQbMeFUcteIhgoAGFNnfIEe-oPK4U3OekwMI97h1bl8gwB5m9wrbqB_Pz3tccs4KWEIpggxuu9gyId_sceOUe2spevyuC9kMA3dFevcoiommnRy4-EwOjwm2OtJFCOg-qPrYs0LWjtFikV0Y3-hA30iAbqIJBESwHbSpJoXZZnF8_8eDIegP-MesGpbSl5TWDeFC1Y43ZN1WaxotyHHT5PmW6N4V1P2d9L8O__XnPRv-3wC3L6mvowhWbBvo7lJvoLRTYplgYPc5wtwNzWb5niOPkQHohuClMi0tVA7THAuFDObOhpPvp87SvHPfC86Uc4X_RTptp-9pwBEOsj7B-MsUiPCCXgXgDp5V3YGbsuPbVuA85FREK0afSXA6QejRq6Vd0MX13xOaE',
          coverPhotoMediaItemId:
            'ACPp6nMcg4TI0hPO_jqHiG4kjEf1SrwT5BOC3O8L3NP3RGQPbIq6ui6J6Y8U4B6Gq1PL4yD0f7-8OpxL-pLwRrAqioNwbCIUHw'
        },
        {
          id: 'ACPp6nPU2n92MKpbCM6OCpUopmqiwr9KHjafs_CCAkaIZBtGMF3mHoskGJ0fW3eKbGDt37aCc6TF',
          title: 'Binntal 2024.2',
          productUrl:
            'https://photos.google.com/lr/album/ACPp6nPU2n92MKpbCM6OCpUopmqiwr9KHjafs_CCAkaIZBtGMF3mHoskGJ0fW3eKbGDt37aCc6TF',
          mediaItemsCount: '146',
          coverPhotoBaseUrl:
            'https://lh3.googleusercontent.com/lr/AAJ1LKdfienxOnrT4qOrB16Gv5MMEsoUz7vvuAMACm8st0-9H1M1g0CQMEovffI0M1BliuMODIvbPiiHt159-vXz6NHM5Ds8683bEOpDqaxlfoaReqHEi2jd1yqAtikBvWaUKoFL3ySHX4ozBbwfeX2RQHEH8fT3fnjhXXBgoaWehxelQZvktAvYn3RMgmylVrKgi8lSV4PHM8QqHgzEQTmnbQ4Quu0sjADVyEfFEPqWEf8ZiAJFAvpbzYwcQxBAfEHSWEXItvKTCpLcotatkS0hMkX3ylKRHyyhdem7fk3hovpCAzGTjnybSW9sQtBMgWUrcm0Pq3nGWKAB5M8jMFkPuwheQRxB8bBWkV_Ck9H5FPlV5EKW3zn-IgixQS5Lf8UCMVSa6LD4rqotdv3MXbp7Km7I3scNntSzk64wMaFO_kLO7-ucI5vqVgNNHxLgZO0uWpkNfC5DGBEzN1R5Q9fQrjq8d588ve0mV8Fx3d6O_DRLqAQwyeDRjeX0fkCUchjQRxmlGSXZWDP9SeaqVwk5yDS1mEAO1vSg02q0RHsvloykX5nB7d89TOzgodnUQP0iyzTKolpENYC4wwMTdKzVv0PL38GlIS8Qg1dczsTx7oztKp6mrt2UfNhxVtEnD7f--0Cjdx9Y0hwC4HMi8k4pTilxbFiC6DT7WJQi4Eb1Bm43j23DQ7agr93AogyIPl6izPQ2tl9WTVMOkbNdsP6Qo5Tc_y4ICRKCqwWue_0qAtI01tlraY-yvxJ8_hl6v3NR5nbnAxvNt8e8qZf54KeIOqxvMcUC53Ogl3-uZGwFYs6ir_OJFqyZBr0BajiPljIm6D3vC_qxddhBpfFoRvDn6ETYLkSYik1tFL_tT0qtbbNB9TF8X_aX4_m-2keX_IW0nswy87N5rQn2oiF1DCfpmDmQRCMTAN6QVxssyaJrjdiuRHP0RNTTnPvP6-_RsJnphqB9Z7B553Dd5nrS3tZcHmIJSRh7sqEHoj_pa4i34LKOYzM',
          coverPhotoMediaItemId:
            'ACPp6nM92hkk2bo7dsJGiDIJuPZ1jc-RLS5ocXB4mglLg7_w03CG5CzSYLqP10rx8KoBdpnup5-DcrTDYzWl9GZTzHxDHDIZ3A'
        },
        {
          id: 'ACPp6nPBhNqrsRrORYRpmR4bFK6VjRUBvAMuuZhWMg_Kr0n_WB-NrB2p_60J6qxi0hNI-ey5dQk-',
          title: 'My Stars',
          productUrl:
            'https://photos.google.com/lr/album/ACPp6nPBhNqrsRrORYRpmR4bFK6VjRUBvAMuuZhWMg_Kr0n_WB-NrB2p_60J6qxi0hNI-ey5dQk-',
          mediaItemsCount: '444',
          coverPhotoBaseUrl:
            'https://lh3.googleusercontent.com/lr/AAJ1LKc_UG3UrDzI5SSFKUoTUahQZ0hFera_QK8syj3IDxJYlGpnkR_b8DKzcWnzbloN-iAHxywcgnYTc1bJiwyJ3usEIJm7wuv43fcjSU7kn3Ry23drT96DtY52-tQWKWwmtkDsmGUNrQdGY_fkuQNAdjKEGwz9o_Gbd2IWBCIDJSh-mrdISRJcgeuN2ex1dQfRNOz6z53mMQEQkROu50A5tglFYmkpmbXgLpvCJrf_Kwj0obsOQUBCy801bZFijZ2IR024UeeMnqilrNKVBCapT6c4DwEI8SzQ-XsjqeJv1WI3w1x6W8xPwmtSBNHHKIMEo2UMZ2zQ3ArxPEDw5j11S1s0FfYiB3VgOC71MmFh_LXESxXn7XtcSEA1XUKyje9jBty2yIxM_jWJetDeL6EaZbt3zvCS6KuQVTrf5BT9egBMSXWpofTjFF6SsuWGsDp9L_RxPAWPpQKbhpPCKqpjtK3kBiJtxQTEpq-6rBP2_2bAlUCCHjqKKWRIzpMnbdtUVa4CqIx_spsWeLCmxxFS1_Ly4YOa0E1Y6MTMU5AQKslFqPU4cDbBVTHUHVw84sW6KLunw89YQ4Jvb_UnIl4WOcrbeVLggbKo3XKR6gzRWgSQqemobsxoP1dnXSd_aAShSsK8rVTHNvFNxNMgcCqbPoJJl8rrvDlfQPkpf1qrVg1OnYoYNITYFG7VwTLGFTimGoNAct2dIkyem0Vy8BKUd6zthdAtmBbw8Vyf7FDEt4PTZK1qr4125lEUWM8mAeRHP7bl8pW4B9FwNp5PQPaLaZ13FXXXWl20Wv7wcOpJLztL4_L2qYqZtmTCkSzzZgP2BoBmy9ULE1pbmXTgXmVxYFYiLqEiEFCCC8lTeWGkKGfLMFQt30bVDRC1X89zn9tQ0s2Lpx4FU3rJo-R6IAbcp9mk7_qEaLVGvzTJ6Fsj5WgXIZ6b0g97uH9bJ_LhvaRxIrM_4PPL0l2KblL-5H473Q2RxWyxp3SdY1YC3EgkpcUYYj4',
          coverPhotoMediaItemId:
            'ACPp6nPPjJivwUzxJofNjCJviNjvfu6ZEENAF2ZAwx_sbbly4TcDzoLxchOYbQWwGPiyq1qSb0BgWaA3D1KprvL3B1Z2cdETkg'
        }
      ]
    };
  });

  s.post(`https://photoslibrary.googleapis.com/v1/mediaItems:search`, (a: any, req: Request) => {
    if (req?.requestBody) {
      const body = JSON.parse(req.requestBody);
      if (body.albumId) {
        return {
          mediaItems: [
            {
              id: 'ACPp6nN0PXROSQ6GW5e98I5uan8mtHzb-P94AY6AVfoJWqPNna8j7rRywWg7otm26kyYw-VE2bwA',
              productUrl:
                'https://photos.google.com/lr/album/ACPp6nOWPV3G959upm5peBsp_lo_Ff7ef3o9JiMymduF-a4gVqE028dFYM1hFOOjUdz3m2d3eucE/photo/ACPp6nN0PXROSQ6GW5e98I5uan8mtHzb-P94AY6AVfoJWqPNna8j7rRywWg7otm26kyYw-VE2bwA',
              baseUrl:
                'https://lh3.googleusercontent.com/lr/AAJ1LKc1KpTR-YtoBsunDuiG9Ia-X9JaELgnwCkNQBc2Nhb0jarwL14LCIchNfuxSlN0k9VsCQgtUK6Dtk_TC3ed3sf2HBtPj1ugCCDw5LD8xdQrqVcvzTQnDSuIH8PrOJDqd_iem6nf1YWYDJ92TWMWnG2Fqyr_R2eOQ5kTqVC_eHGUCcxI7cXxd0ADv1VtaP9YH188IUf6W_vGLo9nZryGAnDuQj_lQqOMkwGix2Wacz5qVIrWTwvlLyoiMQbTfXtBQtjxxuDtydMr4AsnFG4J8LgB8qfIrY7hjO3A8G2ZTylrihho0hzFNOBq0Jd44CH0SZlQv1l_uUKyqfUSata2m18dYDLnsHqoSMHUasHoEF7iVM_7ovZxfabf0XXNUXt9T_1hOX0reupPcxquX36KC-b5o-eargRFmtfZlgDHqLw_GkBUjMDXvJY5BXYM-J57eRV8i9imXRKxhCAqj0HYPwztfS2Y6xzTFE81Ns99nAdoROM9F4ApUhTD09c5QqcsMCCUi7oPvRUYn_fWXg3lOwsJQim6Bp-yIYpW4Lyk9_-Xro2AiZWT3F8EjtLaPmfh7-BnkT16aECd9f4yZGlK9DCeoeoC2wwKejuD4V0_1PEAbqnrv8JjKdRhnqgtNOACToPi_2Sz2q_EE50oX86EeDO_KgiDAeOUK-XnzYkqZbMQrk9GZCFdakvv6CMfNJwPNVgFNLDZs8YGciXYHqRDsHvTRCsg_j5OV_irJ_3Zv3w2U0Nk66P8krbXkKuRblHWoSSwpa5PpFsq9aZVm_9ufhpQF14uL_L6S_eBLeZlGrCnIDnehJZX_ib-eU-MVsXPKVa6nz1SZ3qYFqCyrE6MBFSR3iMXDKkHnbIcaX6c5eRFfocy_zZ0c3RohQwM9CgPkuP77cF3sCpH6_PLEkNWEL6oJOkE2AQtCYYu9hKmaWLiIvQWv2jbU7z2DI_WYmBwNl6adRzou9km27ZfjBAvRtC-7tA_z7wVtMn_pF_U7MNjDg',
              mimeType: 'image/jpeg',
              mediaMetadata: {
                creationTime: '2024-07-26T18:47:08.341Z',
                width: '4000',
                height: '3000',
                photo: {
                  cameraMake: 'Fairphone',
                  cameraModel: 'FP3',
                  focalLength: 3.56,
                  apertureFNumber: 1.8,
                  isoEquivalent: 416,
                  exposureTime: '0.033333335s'
                }
              },
              filename: '2024-07-26 20.47.08.jpg'
            },
            {
              id: 'ACPp6nMzv2t_k6f02gFt0mF9P6b_U29X4yNYrIgyh8IReeSeZRk5GBMin9icgbx324pM3Qavvofo',
              productUrl:
                'https://photos.google.com/lr/album/ACPp6nOWPV3G959upm5peBsp_lo_Ff7ef3o9JiMymduF-a4gVqE028dFYM1hFOOjUdz3m2d3eucE/photo/ACPp6nMzv2t_k6f02gFt0mF9P6b_U29X4yNYrIgyh8IReeSeZRk5GBMin9icgbx324pM3Qavvofo',
              baseUrl:
                'https://lh3.googleusercontent.com/lr/AAJ1LKeNUHRQPM5fjirnRBCYceVuSEuhbLzhrzbmL8biDNW2_HPxZfTn4ljem5U-z9IOUD4O0YER08QDzloVkO1hB94COZ0JcZXoy6XgDEbULl90z2bDy44TDWQJHbp9IabDVWyaDPUfiV3NviueazvHUyVulDJ5BsLC1IFpVlL80p7f9J0mYWAjzvRrd2ZgNYJvUTdQUSSMo3J7qs5fXeebkr15_tYyJncV41_9xheH8aCKEllnOlhcuVPRMqzm2FW7O4oYw4ZM3Gm8zkn1V1W6IVMfwzKGcRgjxlp259y2CG_5Oji7Hpz3FJkzmbNMEctucMX2DUuuSnBvyX9JiQkF59agGmMx4P83FK5T0THkES-8xSQRAFpsDBunDOqatzAKbJkZ9ryA28JNg3_PbeKjsU4TaPt8f7VqAf6JIZENJhttFOry3XVY-fu8XQxshT5fzb4Ez_Xi9MedxGz68meSt4sw8VYn1v6EzCT5h6ntJrnRnZTR6gqntEc1fD9Z8ugDmD9G8tuq3CCXmWwJPNuCbB8i-Afuwk3BsveAB_-H_9zYQ3Z0AG_NZe_n89P7lMPTbhDY3mXMK2soFkrLmGoA9lQ_7bTLUh_l-J4UznzyyQF8FtusX_SkFbe-QPdfxy0RlwmXdnLI8cZ_79JaM3yu8_McEJLwyPL-LEHAUXYWpy6slHUSPZ9yfWvf279-FEf610U9uCmG2SQ3ImvjX_GAdMQseAHc8wf46nO4jGDXs2LyPxuZx_K1tmelnJSgGNdo0RElE5WLpWwoVv22QN_mHqDdaRWF99WbkXhhJQNaWSE6LavuDjtcIDEnzk-AM7VA-gBVeZZM02cBX5GmZdoy2gZoV0pwmTOPs2S0O1_HH2bWrSUQRL_OcEeke7bfBncGVeHnSNPDs6CxI9TH5GlE5Dog_JHU2OvTwNYrC3HGRMCnEuCLfWPQ6nEhdSd-kGJmDCPL4MbmDNS2y9l-3E-Urc1IGS0PKQ1sZk7Gyx3fdHnJEQ',
              mimeType: 'image/jpeg',
              mediaMetadata: {
                creationTime: '2024-07-26T16:34:46.359Z',
                width: '4000',
                height: '3000',
                photo: {
                  cameraMake: 'Fairphone',
                  cameraModel: 'FP3',
                  focalLength: 3.56,
                  apertureFNumber: 1.8,
                  isoEquivalent: 135,
                  exposureTime: '0.008333333s'
                }
              },
              filename: '2024-07-26 18.34.46.jpg'
            },
            {
              id: 'ACPp6nO9IXVFBV8TR53UOLTOgGz6NB0OemyjJ59JMePBuQasXe6GbSHHVNpZ2qVArmeIQgc-dNeb',
              productUrl:
                'https://photos.google.com/lr/album/ACPp6nOWPV3G959upm5peBsp_lo_Ff7ef3o9JiMymduF-a4gVqE028dFYM1hFOOjUdz3m2d3eucE/photo/ACPp6nO9IXVFBV8TR53UOLTOgGz6NB0OemyjJ59JMePBuQasXe6GbSHHVNpZ2qVArmeIQgc-dNeb',
              baseUrl:
                'https://lh3.googleusercontent.com/lr/AAJ1LKfMpYBseBfqtolk5UNzbV9dP5fsR94eW5FuhZwYyeuQZLWkea6ZDQnAQ6LGf4Sh5FN60CFTZg2XNLU_oBHTArJt8XNxKqxNNeHSTHeIOILjeMRrILrSBV4ZHenXBiRduCY6B9X63IF9DKsrG8Wmw_URV3CDhjd_kTX6uRSXibe98pkcuK28aOsCecGnB04S4mDVUeBtQBvRco-_KgkNIKZY7bmHEPKzHQpEHPEs470v1K4hi2asZO0hYREOZopzA_4plYHtJrDKARVy8-9Cu6X_SPv_32yWrzNxp1nwOfP3pV_XO17AZv8gfFKMP0pcw1_o56UX3fli-GAWRMKlzNJKFc_2kLaw5P9voAtjiYzKJtz6x37F6mVSIkEcrKC0yGKWTzJSopXDnNEDDKAMcPikyi_hXYR-79qyp64llm0mvC6aZzI2eQJ3oK2ObVwR5Zue64qyDer3NKiVNs1yzsHqVVZUoHmzRNLnEJ5624ri41ZDRk7x4tcKg5gOiRJseR-mwY4I9bEqTHS3vs3nDudBJ41NG111ol90TKOY_LFYdduScD_eQq_SqHzfWsg6_NRW4ex45kHl60ZxJkAU13dmwfGm1uZwrfa_tubxCzwBUveQTW947U0s1SqMLeDwSgVUdtUZPEUai9BorYH10jVh-q7W6h3vb6eyjlUrnDerTKLctdrEnv3ERIJsWzbjlcDsncI5zS-9kB8mMcrm_GX6FkF9EKu0UDYHwi4IhCewNBeL3ktiwnA5P8PDREjnXsH-p8mu1fsr26E4jNFcFYqhJIxGBXu10YqqoBGyXlaRcvT_8DFY5MmCZb2-e2zyjMcVH5RmYTnsoohxeHJvxZnpUNoFCJlYlXDhOwOyu7xz7S7rSmh-4SLwz_5FYeO8Uc_sG-vwgw-TQx435LeNRl-GfTidjfVHdz38NMxDNO3pDwEAzkPD_Pp0pL8qsfnvkh6UzICcFFMtKr9-ztsYDR1qHqpgNrGPE-FYn4r4rme7Ag',
              mimeType: 'image/jpeg',
              mediaMetadata: {
                creationTime: '2024-07-26T16:24:18.787Z',
                width: '3000',
                height: '4000',
                photo: {
                  cameraMake: 'Fairphone',
                  cameraModel: 'FP3',
                  focalLength: 3.56,
                  apertureFNumber: 1.8,
                  isoEquivalent: 107,
                  exposureTime: '0.008333333s'
                }
              },
              filename: '2024-07-26 18.24.18-1.jpg'
            }
          ]
        };
      }
    }
    return {};
  });

  s.get(`https://photoslibrary.googleapis.com/v1/albums/:albumId`, () => {
    return {
      id: 'ACPp6nPU2n92MKpbCM6OCpUopmqiwr9KHjafs_CCAkaIZBtGMF3mHoskGJ0fW3eKbGDt37aCc6TF',
      title: 'Binntal 2024.2',
      productUrl:
        'https://photos.google.com/lr/album/ACPp6nPU2n92MKpbCM6OCpUopmqiwr9KHjafs_CCAkaIZBtGMF3mHoskGJ0fW3eKbGDt37aCc6TF',
      mediaItemsCount: '146',
      coverPhotoBaseUrl:
        'https://lh3.googleusercontent.com/lr/AAJ1LKdfienxOnrT4qOrB16Gv5MMEsoUz7vvuAMACm8st0-9H1M1g0CQMEovffI0M1BliuMODIvbPiiHt159-vXz6NHM5Ds8683bEOpDqaxlfoaReqHEi2jd1yqAtikBvWaUKoFL3ySHX4ozBbwfeX2RQHEH8fT3fnjhXXBgoaWehxelQZvktAvYn3RMgmylVrKgi8lSV4PHM8QqHgzEQTmnbQ4Quu0sjADVyEfFEPqWEf8ZiAJFAvpbzYwcQxBAfEHSWEXItvKTCpLcotatkS0hMkX3ylKRHyyhdem7fk3hovpCAzGTjnybSW9sQtBMgWUrcm0Pq3nGWKAB5M8jMFkPuwheQRxB8bBWkV_Ck9H5FPlV5EKW3zn-IgixQS5Lf8UCMVSa6LD4rqotdv3MXbp7Km7I3scNntSzk64wMaFO_kLO7-ucI5vqVgNNHxLgZO0uWpkNfC5DGBEzN1R5Q9fQrjq8d588ve0mV8Fx3d6O_DRLqAQwyeDRjeX0fkCUchjQRxmlGSXZWDP9SeaqVwk5yDS1mEAO1vSg02q0RHsvloykX5nB7d89TOzgodnUQP0iyzTKolpENYC4wwMTdKzVv0PL38GlIS8Qg1dczsTx7oztKp6mrt2UfNhxVtEnD7f--0Cjdx9Y0hwC4HMi8k4pTilxbFiC6DT7WJQi4Eb1Bm43j23DQ7agr93AogyIPl6izPQ2tl9WTVMOkbNdsP6Qo5Tc_y4ICRKCqwWue_0qAtI01tlraY-yvxJ8_hl6v3NR5nbnAxvNt8e8qZf54KeIOqxvMcUC53Ogl3-uZGwFYs6ir_OJFqyZBr0BajiPljIm6D3vC_qxddhBpfFoRvDn6ETYLkSYik1tFL_tT0qtbbNB9TF8X_aX4_m-2keX_IW0nswy87N5rQn2oiF1DCfpmDmQRCMTAN6QVxssyaJrjdiuRHP0RNTTnPvP6-_RsJnphqB9Z7B553Dd5nrS3tZcHmIJSRh7sqEHoj_pa4i34LKOYzM',
      coverPhotoMediaItemId:
        'ACPp6nM92hkk2bo7dsJGiDIJuPZ1jc-RLS5ocXB4mglLg7_w03CG5CzSYLqP10rx8KoBdpnup5-DcrTDYzWl9GZTzHxDHDIZ3A'
    };
  });
}

function googleDriveRoutes(s: Server) {
  s.get(`https://www.googleapis.com/drive/v3/files/:id?alt=media`, () => {
    return {
      streams: [
        {
          id: 'first-stream',
          title: 'Your first stream',
          color: '#ff0000'
        },
        {
          id: 'ezbal8fc7kog33_myf7s6',
          title: 'Second Stream',
          color: '#477676'
        }
      ],
      items: [
        {
          id: '1p_drzooki9umd0cqt244',
          startDate: 1679526000,
          title: 'T_Chamana Jenatsch',
          description: '',
          stream: 'first-stream',
          album: 'ACPp6nOlVQSbuQ-_3IvFySPeT2va3lWn6l2matC8Qyf4mYRye-UZgWDu6OqWZwNKsTeJOm3RKYzg',
          place: {
            location: {
              lat: 47.398725887133494,
              lng: 8.450168291015645
            },
            name: '8952 Schlieren, Switzerland'
          }
        },
        {
          id: 'first-item',
          startDate: 1713132000,
          title: 'T_ZÃ¼hlke tagged',
          description: 'This is your first item',
          stream: 'first-stream',
          ongoing: true,
          album: 'ACPp6nODSWH3UuwUFJ49Ou6dVw7NaaCgvIzv5IUZXWwGHIBW2aaeRSMPSWnr1wWp--aA3e2_pFqI'
        },
        {
          id: 'hyqqc1z818u_rx-otesva',
          startDate: 1562796000,
          title: 'T_IsebÃ¤hnle',
          description: 'dagdsgadg',
          stream: 'ezbal8fc7kog33_myf7s6',
          ongoing: true,
          album: 'ACPp6nNlijXY_zEH_iwzQ0fvUP_7RigNyZbWH1HlVBfjGalztwi_bPDrEs4UhUPJisozLRilWg1E',
          place: {
            location: {
              lat: 46.819732932815775,
              lng: 8.40673430793457
            },
            name: 'Engelberg, Switzerland'
          }
        },
        {
          id: 'nakueyeq1tgqeeerp6ti6',
          startDate: 1750802400,
          title: 'Title sd asdgasd gsdg adsg',
          description: '',
          stream: 'ezbal8fc7kog33_myf7s6',
          place: {
            location: {
              lat: 46.85864569869188,
              lng: 8.041352565465182
            },
            name: '6173 FlÃ¼hli, Switzerland'
          }
        },
        {
          id: '7v2bho97anljbp1sp0799',
          startDate: 1745964000,
          title: 'Title',
          description: '',
          stream: 'ezbal8fc7kog33_myf7s6',
          album: 'ACPp6nOWPV3G959upm5peBsp_lo_Ff7ef3o9JiMymduF-a4gVqE028dFYM1hFOOjUdz3m2d3eucE',
          place: {
            location: {
              lat: 46.81856491966132,
              lng: 8.143892075000018
            },
            name: '6074 Giswil, Switzerland'
          }
        }
      ],
      birth: 946681200
    };
  });

  s.get('https://www.googleapis.com/drive/v3/files', () => {
    return {
      files: [
        {
          id: driveFileId,
          name: 'myLife.dev.json',
          trashed: false
        }
      ]
    };
  });

  s.patch(
    'https://www.googleapis.com/upload/drive/v3/files/:fileId?uploadType=multipart&supportsAllDrives=true',
    (a, req: Request) => {
      return {
        kind: 'drive#file',
        id: driveFileId,
        name: 'myLife.dev.json',
        mimeType: 'text/plain'
      };
    }
  );
}

const DUMMY_TOKEN_EXPIRE_IN_SECONDS = 60 * 60;

function googleAuthRoutes(s: Server) {
  s.get('https://www.googleapis.com/oauth2/v3/userinfo', () => {
    return {
      accessToken: 'SUUUUPER_MOCK_ACCESS_TOKEN',
      expiresIn: DUMMY_TOKEN_EXPIRE_IN_SECONDS,
      expiresAt: addSeconds(new Date(), DUMMY_TOKEN_EXPIRE_IN_SECONDS).getTime(),
      email: 'mock.xeronimus@gmail.com',
      name: 'MOCK Sergio Trentini',
      sub: '103949975110131559430',
      profileImg: 'https://lh3.googleusercontent.com/a-/AOh14GhHoJKrGyYY-0IanQF74R8VXz-j6eCZmgtC_YDy9g=s96-c'
    };
  });
}
