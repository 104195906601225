import React, {useRef} from 'react';
import {useRecoilValue} from 'recoil';

import Stream from '../../../domain/Stream';
import Boundaries from '../../../domain/Boundaries';
import StreamLaneItem from './StreamLaneItem';
import {zoomedOutModeAtom} from '../../../state/atoms.ui';
import {getItemRowsForStreamSelector} from '../../../state/selectors';

import {StyledStream, StyledStreamLabel, StyledStreamRow} from './_styled';
import LifeItemWithStreamProps from '../../../domain/LifeItemWithStreamProps';

interface StreamLaneTypes {
  stream: Stream;
  boundaries: Boundaries;
  onAddNewItem: (itemStartDaysDelta: number) => void;
  onDeselectItem: () => void;
  onSelectItem: (itemId: string) => void;
}

/**
 * a component that displays one of the streams as a horizontal "lane".
 * contains life items as "cards".  since we do not want items to overlap, a lane displays items in "Rows"
 */
const StreamLane = ({stream, boundaries, onAddNewItem, onDeselectItem, onSelectItem}: StreamLaneTypes) => {
  const {firstDay, totalDaysInSpan} = boundaries;
  const zoomedOutMode = useRecoilValue(zoomedOutModeAtom);
  const rows = useRecoilValue(getItemRowsForStreamSelector(stream.id));
  const laneRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  return (
    <StyledStream $color={stream.color} onClick={onStreamClick} ref={laneRef}>
      <StyledStreamLabel>
        <div>{stream.title}</div>
        <div>{stream.title}</div>
      </StyledStreamLabel>
      {rows.map((items, rowIndex) => (
        <StreamLaneRow
          earliestDayInSpan={firstDay}
          totalDaysInSpan={totalDaysInSpan}
          key={`row:${stream.id}:${rowIndex}`}
          rowIndex={rowIndex}
          streamId={stream.id}
          items={items}
          onItemClick={onSelectItem}
        />
      ))}
    </StyledStream>
  );

  function onStreamClick(e: React.MouseEvent) {
    if (e.ctrlKey && laneRef?.current && laneRef.current.getBoundingClientRect) {
      // find "position" (day) to insert new item / happening / event
      const xPosition = e.clientX - laneRef.current.getBoundingClientRect().left; // horizontal position in pixels equals days. 1day = 1px
      // if zoomed out mode is active, multiply by 2 to get the correct date from the click position
      const daysIn = zoomedOutMode ? xPosition * 2 : xPosition;
      onAddNewItem(daysIn);
    } else {
      onDeselectItem();
    }
  }
};

interface StreamLaneRowTypes {
  items: LifeItemWithStreamProps[];
  earliestDayInSpan: Date;
  totalDaysInSpan: number;
  streamId: string;
  rowIndex: number;
  onItemClick: (itemId: string) => void;
}

const StreamLaneRow = ({
  items,
  earliestDayInSpan,
  totalDaysInSpan,
  streamId,
  rowIndex,
  onItemClick
}: StreamLaneRowTypes) => (
  <StyledStreamRow $index={rowIndex}>
    {items.map((item, itemIndex) => (
      <StreamLaneItem
        key={`item:${streamId}:${itemIndex}:${rowIndex}`}
        item={item}
        earliestDayInSpan={earliestDayInSpan}
        totalDaysInSpan={totalDaysInSpan}
        onItemClick={() => onItemClick(item.id)}
      />
    ))}
  </StyledStreamRow>
);

export default StreamLane;
