import {useMemo} from 'react';
import {differenceInDays, getDaysInYear, getYear} from 'date-fns';

import LifeItem from '../../domain/LifeItem';
import {isBeforeDate} from '../../data/timeUtil';

/**
 *
 * @param {number} year
 * @returns {number}
 */
export const useCalculateMonthsTimeLineWidth = (year: number): number => useCalculateSpotlightStreamsWrapperWidth(year);

/**
 *
 * Note: the spotlight year is extended to include <b>december of previous year</b> and <b>january of the following year</b>!
 */
export const useCalculateSpotlightStreamsWrapperWidth = (year: number): number =>
  useMemo(() => {
    return (getDaysInYear(new Date(year, 0, 1)) + 31 + 31) * 10;
  }, [year]);

/**
 * left offset in pixel for an item in a spotlight year
 */
export const useCalculateSpotlightStreamItemLeftOffset = (item: LifeItem): number =>
  useMemo(() => {
    // in spotlight view, all items have the same year (startDate) -> derive the earliest day in the span as the 1st December of the previous year
    const earliestDayInSpan = new Date(getYear(item.startDateObj) - 1, 11, 1);
    const daysSinceEarliest = differenceInDays(item.startDateObj, earliestDayInSpan);
    return daysSinceEarliest * 10;
  }, [item]);

export const useCalculateSpotlightStreamItemBarWidth = (item: LifeItem): number =>
  useMemo(() => {
    if (item.ongoing) {
      // last day of spotlight time frame is 31.January of next year
      const lastDay = new Date(getYear(item.startDateObj) + 1, 0, 31);
      return differenceInDays(lastDay, item.startDateObj) * 10;
    }

    if (!item.endDateObj) {
      return 0;
    }

    // if we have an end date, check whether it is before 31.January of next year
    const endsWithinSpotlightTimeFrame = isBeforeDate(item.endDateObj, new Date(getYear(item.startDateObj) + 1, 1, 1));
    if (endsWithinSpotlightTimeFrame) {
      return differenceInDays(item.endDateObj, item.startDateObj) * 10;
    } else {
      const lastDay = new Date(getYear(item.startDateObj) + 1, 0, 31);
      return differenceInDays(lastDay, item.startDateObj) * 10;
    }
  }, [item]);
