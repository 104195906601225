import LifeItem from './LifeItem';
import Stream from './Stream';
import LifeItemWithStreamProps from './LifeItemWithStreamProps';
import {startDateItemComparator} from '../data/startDateItemComparator';

export default interface Life {
  birth: Date;
  photoAlbumId: string;
  streams: Stream[];
}

export const getMatchingItemOrThrow = (streams: Stream[], itemId: string) => {
  const allItems = getAllItemsFlat(streams);
  const match = allItems.find((i) => i.id === itemId);
  if (!match) {
    throw new Error(`We could not find item with id ${itemId} within the given streams!`);
  }
  return match;
};

export const getAllItemsFlat = (streams: Stream[] | undefined): LifeItemWithStreamProps[] => {
  if (streams && streams.length) {
    return streams.reduce(
      (allItems: LifeItemWithStreamProps[], currentStream) =>
        allItems.concat(denormalizeStreamDataToItems(currentStream.items, currentStream)),
      []
    );
  } else {
    return [];
  }
};

export const getActiveStreams = (streams: Stream[] | undefined, activeIds: string[]): Stream[] => {
  if (streams && streams.length) {
    return streams.filter((s) => activeIds.includes(s.id));
  } else {
    return [];
  }
};

export const findNewestItem = (streams: Stream[]) => {
  const flatItems = getAllItemsFlat(streams);
  if (flatItems.length < 1) {
    throw new Error('We do not expect an empty list of items here!');
  }
  const sorted = flatItems.sort(startDateItemComparator);
  return sorted[sorted.length - 1];
};

export const denormalizeStreamDataToItems = (items: LifeItem[], stream: Stream): LifeItemWithStreamProps[] =>
  items.map((item) => {
    if (item.stream !== stream.id) {
      throw new Error(
        `one of the given items has stream-id "${item.stream}" which does not match id of given stream "${stream.id}"`
      );
    }

    return {...item, streamColor: stream.color, streamTitle: stream.title};
  });

/**
 * Get all items in one year as flat list. Sorted by startDate ascending.
 */
export const getItemsInYearFlat = (streams: Stream[] | undefined, year: number): LifeItemWithStreamProps[] => {
  const allItems = getAllItemsFlat(streams);
  const thisYearItems = allItems.filter((item) => item.startDateObj.getFullYear() === year);
  thisYearItems.sort(startDateItemComparator);
  return thisYearItems;
};
