import React from 'react';

import BuildInfo from './BuildInfo';
import {StyledHelp, StyledShortcutHelp, StyledShortcutKey, StyledShortcutKeyAction, StyledSideBarPart} from './_styled';

const Help = () => {
  return (
    <StyledHelp>
      <h2>Help</h2>

      <StyledSideBarPart>
        <h3>Navigation</h3>
        <p>
          <b>Select a item</b> by clicking on it. The sidebar will now display it's details as well as let you modify
          the item.
        </p>
        <p>
          <b>Pan horizontally</b> by dragging the main view with the right mouse button pressed.
        </p>
        <p>
          <b>Pan vertically (scroll)</b> by scrolling with your mousewheel.
        </p>
      </StyledSideBarPart>

      <StyledSideBarPart>
        <h3>View Options</h3>
        <p>
          <i className="icon-table" /> Toggle vertical <b>grid lines</b> by clicking on this icon in the Toolbar at the
          bottom
        </p>
        <p>
          <i className="icon-resize-small" /> Toggle <b>zoomed-out mode</b> by clicking on this icon in the Toolbar at
          the bottom
        </p>

        <p>
          <b>Spotlight one year in your life</b> by clicking at the year number at the top or bottom of the main view.
        </p>
      </StyledSideBarPart>

      <StyledSideBarPart>
        <h3>Keyboard Shortcuts</h3>

        <StyledShortcutHelp>
          <div>
            <StyledShortcutKey>
              <span>n</span>
            </StyledShortcutKey>
            <StyledShortcutKeyAction>Creates a new item at the current scroll position</StyledShortcutKeyAction>
          </div>
          <div>
            <StyledShortcutKey>
              <span>j</span> or <span>g</span>
            </StyledShortcutKey>
            <StyledShortcutKeyAction>Opens a textfield that lets you jump to a specific year.</StyledShortcutKeyAction>
          </div>
          <div>
            <StyledShortcutKey>
              <span>s</span>
            </StyledShortcutKey>
            <StyledShortcutKeyAction>Focuses the search bar.</StyledShortcutKeyAction>
          </div>
          <div>
            <StyledShortcutKey>
              <span>ctrl</span>+ Mouseclick
            </StyledShortcutKey>
            <StyledShortcutKeyAction>
              Left-clicking somewhere on a stream while holding the <b>ctrl</b> key, creates a new item at that
              date/position.
            </StyledShortcutKeyAction>
          </div>
          <div>
            <StyledShortcutKey>
              <span>esc</span>
            </StyledShortcutKey>
            <StyledShortcutKeyAction>When in Year Spotlight View, exits back to default view</StyledShortcutKeyAction>
          </div>
          <div>
            <StyledShortcutKey>
              <span>ctrl</span>+<span>right</span>
            </StyledShortcutKey>
            <StyledShortcutKeyAction>
              When in Year Spotlight View, navigates to the next year (forward)
            </StyledShortcutKeyAction>
          </div>
          <div>
            <StyledShortcutKey>
              <span>ctrl</span>+<span>left</span>
            </StyledShortcutKey>
            <StyledShortcutKeyAction>
              When in Year Spotlight View, navigates to the previous year (backward)
            </StyledShortcutKeyAction>
          </div>
        </StyledShortcutHelp>
      </StyledSideBarPart>

      <StyledSideBarPart>
        <BuildInfo />
      </StyledSideBarPart>
    </StyledHelp>
  );
};

export default Help;
