import React from 'react';

import {StyledRoundIconButton} from './_styled';

interface RoundIconButtonTypes {
  onClick: (e: React.MouseEvent) => void;
  children?: React.ReactNode;
  className?: string;
  title: string;
  active?: boolean;
  disabled?: boolean;
}

const RoundIconButton = ({onClick, children, className, title, active, disabled}: RoundIconButtonTypes) => (
  <StyledRoundIconButton title={title} onClick={onClick} className={className} $active={active} disabled={disabled}>
    {children}
  </StyledRoundIconButton>
);

export default RoundIconButton;
