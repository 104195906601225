import React from 'react';
import {useRecoilValue} from 'recoil';

import {countAllItemsSelector, getBoundariesSelector, getStreamsOrThrow} from '../../state/selectors';

import {StyledTable} from '../commons/_styled';
import {StyledStatistics} from './_styled';

const Statistics = () => {
  const streams = useRecoilValue(getStreamsOrThrow);
  const boundaries = useRecoilValue(getBoundariesSelector);
  const itemCount = useRecoilValue(countAllItemsSelector);

  return (
    <StyledStatistics>
      <h3>Statistics</h3>

      <StyledTable>
        <div>Lifespan</div>
        <div>
          {boundaries.firstYear} - {boundaries.lastYear} ({boundaries.yearSpan})
        </div>
        <div>Items</div>
        <div>{itemCount}</div>
        <div>Streams</div>
        <div>{streams.length}</div>
      </StyledTable>
    </StyledStatistics>
  );
};
export default Statistics;
