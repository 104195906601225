import {handleFetchResponseErrors} from '../../fetchUtils';
import PhotoPickerService from '../../PhotoPickerService';
import GooglePickerSession from './GooglePickerSession';
import GooglePhotoAlbumService from './GooglePhotoAlbumService';
import PhotoItem from '../../../../domain/PhotoItem';
import googlePhotosAlbumService from './GooglePhotoAlbumService';

/**
 * The new google picker API.
 */

const googlePhotoPickerService: PhotoPickerService = {
  pickPhotos
};

export default googlePhotoPickerService;

async function pickPhotos(
  accessToken: string,
  albumId: string,
  maxNumberOfPhotos: number,
  onItemsSelected?: (count: number) => void
): Promise<[string[], string]> {
  const pickerSession = await createPickerSession(accessToken);

  if (!pickerSession) {
    return [[], ''];
  }

  const windowHandle = window.open(
    pickerSession.pickerUri,
    'Pick Photos',
    'scrollbars=yes,resizable=yes,status=no,location=no,toolbar=no,width=1200,height=800'
  );

  if (!windowHandle) {
    console.error('Could not open popup!!!');
    return [[], ''];
  }

  windowHandle.focus();

  await pollPickerSessionForSelectionFinished(accessToken, pickerSession);

  const pickedPhotoItems = await getPickedItems(accessToken, pickerSession.id, maxNumberOfPhotos);
  console.log(`we got ${pickedPhotoItems.length} items picked`, pickedPhotoItems);

  if (onItemsSelected) {
    onItemsSelected(pickedPhotoItems.length);
  }

  const ensuredAlbumId = await googlePhotosAlbumService.ensureOwnAlbum(accessToken, albumId);

  const itemIdsInOwnAlbum = await GooglePhotoAlbumService.addPhotosToOwnAlbum(
    accessToken,
    ensuredAlbumId,
    pickedPhotoItems
  );

  console.log(`deleting session ${pickerSession.id}`);

  await deletePickerSession(accessToken, pickerSession.id);

  return [itemIdsInOwnAlbum, ensuredAlbumId];
}

async function createPickerSession(accessToken: string): Promise<GooglePickerSession | undefined> {
  const res = await fetch(`https://photospicker.googleapis.com/v1/sessions`, {
    method: 'POST',
    headers: new Headers({Authorization: 'Bearer ' + accessToken}),
    body: JSON.stringify({})
  });

  await handleFetchResponseErrors(res);
  const body = await res.json();

  if (body && body.id && body.pickerUri) {
    return {
      id: body.id,
      pickerUri: body.pickerUri,
      mediaItemsSet: body.mediaItemsSet,
      expireTime: body.expireTime,
      pollingConfig: {
        pollInterval: body.pollingConfig.pollInterval,
        timeoutIn: body.pollingConfig.timeoutIn
      }
    };
  } else {
    console.error('Creating Google Photos Picker Session failed!');
    console.error(body);
  }
}

async function getPickerSession(accessToken: any, sessionId: string): Promise<GooglePickerSession | undefined> {
  const res = await fetch(`https://photospicker.googleapis.com/v1/sessions/${sessionId}`, {
    method: 'GET',
    headers: new Headers({Authorization: 'Bearer ' + accessToken})
  });

  await handleFetchResponseErrors(res);
  const body = await res.json();
  if (body && body.id) {
    if (body.mediaItemsSet) {
      return {
        id: body.id,
        mediaItemsSet: body.mediaItemsSet,
        expireTime: body.expireTime
      };
    } else {
      return {
        id: body.id,
        pickerUri: body.pickerUri,
        mediaItemsSet: body.mediaItemsSet,
        expireTime: body.expireTime,
        pollingConfig: {
          pollInterval: body.pollingConfig.pollInterval,
          timeoutIn: body.pollingConfig.timeoutIn
        }
      };
    }
  } else {
    console.error(`Getting Google Photos Picker Session with id ${sessionId} failed!`);
    console.error(body);
  }
}

async function deletePickerSession(accessToken: string, sessionId: string) {
  const res = await fetch(`https://photospicker.googleapis.com/v1/sessions/${sessionId}`, {
    method: 'DELETE',
    headers: new Headers({Authorization: 'Bearer ' + accessToken})
  });

  await handleFetchResponseErrors(res);
  return res.json();
}

async function pollPickerSessionForSelectionFinished(accessToken: string, pickerSession: GooglePickerSession) {
  const pollIntervall = 2000;

  return new Promise((resolve) => {
    const intervalId = setInterval(async () => {
      const sess = await getPickerSession(accessToken, pickerSession.id);

      if (sess && sess.mediaItemsSet) {
        clearInterval(intervalId);
        resolve(sess.mediaItemsSet);
      }
    }, pollIntervall);
  });
}

async function getPickedItems(accessToken: string, sessionId: string, maxNumberOfPhotos: number): Promise<PhotoItem[]> {
  return getPickedItemsPaginated(accessToken, sessionId, maxNumberOfPhotos, []);
}

/**
 * https://developers.google.com/photos/picker/reference/rest/v1/mediaItems/list
 */
async function getPickedItemsPaginated(
  accessToken: string,
  sessionId: string,
  maxNumberOfPhotos: number,
  itemsSoFar: PhotoItem[],
  pageToken?: string
): Promise<PhotoItem[]> {
  if (itemsSoFar.length >= maxNumberOfPhotos) {
    return itemsSoFar;
  }

  const params = new URLSearchParams({
    sessionId: sessionId,
    pageSize: '100'
  });

  if (pageToken) {
    params.set('pageToken', pageToken);
  }

  const res = await fetch(`https://photospicker.googleapis.com/v1/mediaItems?${params.toString()}`, {
    method: 'GET',
    headers: new Headers({Authorization: 'Bearer ' + accessToken})
  });

  await handleFetchResponseErrors(res);

  const body = await res.json();

  const photoOnlyMediaItems: PhotoItem = body.mediaItems
    .filter((mi: any) => mi.type === 'PHOTO')
    .map((mi: any) => ({
      id: mi.id,
      baseUrl: mi.mediaFile.baseUrl,
      date: mi.createTime
    }));

  const concatenatedItems = itemsSoFar.concat(photoOnlyMediaItems);

  if (body.nextPageToken) {
    return getPickedItemsPaginated(accessToken, sessionId, maxNumberOfPhotos, concatenatedItems, body.nextPageToken);
  } else {
    return concatenatedItems;
  }
}
