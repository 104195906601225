import styled from 'styled-components';
import tinycolor from 'tinycolor2';

import {FONT_GREY, FONT_LIGHT_BORDER_GREY, HIGHLIGHTING, PRIMARY, WARNING} from '../colors';

interface StyledButtonProps {
  $active?: boolean;
}

export const StyledButton = styled.button<StyledButtonProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  color: ${(props) => (props.$active && !props.disabled ? HIGHLIGHTING : props.disabled ? '#ccc' : 'inherit')};

  background: #e6e6e6;
  border: none;
  border-radius: 1em;
  outline: none;
  padding: 6px 12px;

  > i {
    display: block;
    margin: 0 4px 0 0;
  }

  &:hover,
  &:focus,
  &:active {
    box-shadow: ${(props) => (props.disabled ? 'none' : ' 0px 0px 7px 0px rgb(165, 165, 165)')};
  }
`;

export const StyledPrimaryButton = styled(StyledButton)`
  background: ${(props) => (!props.disabled ? PRIMARY : '#ccc')};
  color: white;

  &:hover,
  &:focus,
  &:active {
    color: white;
    box-shadow: 0 0 7px 0 rgba(97, 155, 242, 1);
  }
`;

interface StyledIconButtonProps {
  disabled?: boolean;
  $active?: boolean;
}

export const StyledIconButton = styled.div<StyledIconButtonProps>`
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};

  color: ${(props) => (props.disabled ? '#ccc' : 'inherit')};

  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.6em;
  height: 1.6em;
  padding: 0;

  &:hover,
  &:focus,
  &:active {
    background: ${(props) => (props.disabled ? 'transparent' : HIGHLIGHTING)};
  }
`;

export const StyledRoundIconButton = styled(StyledIconButton)`
  border: 1px solid ${FONT_LIGHT_BORDER_GREY};
  border-radius: 50%;
  padding: 4px;
  background: white;
  box-shadow: -6px 1px 9px 0 rgb(0 0 0 / 9%);
  width: 24px;
  height: 24px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledCheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  user-select: none;
  cursor: pointer;
`;

interface StyledCheckboxMarkerProps {
  $checked: boolean;
}

export const StyledCheckboxMarker = styled.span<StyledCheckboxMarkerProps>`
  margin-left: 4px;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  width: 16px;
  height: 16px;

  border: 2px solid ${FONT_GREY};
  border-radius: 50%;

  &:after {
    content: ' ';
    position: absolute;
    width: 8px;
    height: 8px;
    background: ${(props) => (props.$checked ? FONT_GREY : 'transparent')};
    border-radius: 50%;
    top: 2px;
    left: 2px;
  }
`;

export const StyledSpinner = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;
`;

export const StyledPullThemRight = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const StyledTable = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  > div {
    width: 50%;
  }
`;

interface StyledInputProps {
  $warning?: boolean;
}

export const StyledInput = styled.input<StyledInputProps>`
  display: block;
  width: 100%;
  border: none;
  background: transparent;
  outline: none;
  margin-bottom: 8px;
  margin-top: 8px;
  border-bottom: 1px solid ${(props) => (props.$warning ? WARNING : FONT_LIGHT_BORDER_GREY)};
  color: ${(props) => (props.$warning ? WARNING : 'inherit')};

  &:hover {
    border-bottom: 1px solid ${(props) => (props.$warning ? WARNING : FONT_GREY)};
  }

  &:active,
  &:focus {
    border-bottom: 1px solid ${(props) => (props.$warning ? WARNING : HIGHLIGHTING)};
  }
`;

//  ---- PHOTO DISPLAY
export const StyledPhotoDisplay = styled.div``;

export const StyledPhotoDisplayInner = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const StyledPhotoItemPlaceholder = styled.div`
  margin-right: 4px;
  margin-bottom: 4px;
  border-radius: 4px;
  background: rgba(90, 90, 90, 0.05);
  width: 160px;
  height: 160px;

  display: flex;
  align-items: center;
  justify-content: center;

  ${StyledSpinner} {
    padding-bottom: 0;
  }
`;

export const StyledPhotoItem = styled.div<{$hoverable?: boolean}>`
  margin-right: 4px;

  > img {
    border-radius: 8px;
  }

  position: relative;

  &:hover {
    cursor: ${(props) => (props.$hoverable ? 'pointer' : 'default')};
  }

  &:hover:before {
    display: ${(props) => (props.$hoverable ? 'block' : 'none')};
    content: ' ';
    position: absolute;
    top: 0;
    bottom: 4px;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 8px;
  }

  &:hover:after {
    display: ${(props) => (props.$hoverable ? 'block' : 'none')};
    font-family: 'my-life';
    font-style: normal;
    font-weight: normal;
    content: '\\e807';
    position: absolute;
    color: white;
    top: 45%;
    left: 45%;
    height: 1em;
    width: 1em;
  }
`;

export const StyledPhotoAlbumLink = styled.div<{$size: number}>`
  margin-right: 4px;
  border-radius: 8px;

  width: ${(props) => props.$size}px;
  height: ${(props) => props.$size}px;
  border: 1px solid ${FONT_LIGHT_BORDER_GREY};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > a {
    text-align: center;
  }
`;

interface StyledStreamSelectItemProps {
  $selected: boolean;
}

export const StyledStreamSelectItem = styled.span<StyledStreamSelectItemProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  cursor: pointer;
  border-bottom: 1px solid #fff;
  background: ${(props) => (props.$selected ? HIGHLIGHTING : 'white')};

  &:hover {
    background: ${(props) => (props.$selected ? HIGHLIGHTING : `#${tinycolor(HIGHLIGHTING).setAlpha(0.1).toHex()}`)};
  }
`;

export const StyledColorDot = styled.div.attrs((props) => ({
  style: {
    background: props.color
  }
}))`
  border: 2px solid white;
  display: inline-block;
  border-radius: 50%;
  width: 20px;
  height: 20px;
`;

export const StyledFavIcon = styled.div`
  display: inline-block;
  border-radius: 20%;
  background: white;
  width: 1em;
  height: 1em;
  padding: 2px;
`;

export const StyledButtonSvgWrapper = styled.div`
  height: 18px;
  margin-right: 8px;
  min-width: 18px;
  width: 18px;
  display: inline-block;
`;
