import React, {useEffect, useState} from 'react';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';

import OptionsBar from './OptionsBar';
import Life from './life/Life';
import SideBar from './sidebar/SideBar';
import Spinner from './commons/Spinner';
import SpotlightYear from './life/SpotlightYear';
import KeyboardShortcutsListener from './KeyboardShortcutsListener';
import SpotlightOptionsBar from './SpotlightOptionsBar';

import {birthAtom, photoAlbumIdAtom, streamsAtom} from '../state/atoms';
import {spotlightYearAtom} from '../state/atoms.ui';
import {userContextAtom} from '../state/atoms.user';
import useActionSetStreams from '../state/actions/setStreams';
import RecoilDebugObserver from '../state/RecoilDebugObserver';

import {loadLife} from '../data/service/lifeService';
import ctxPersistorService from '../data/service/impl/LSContextPersistorService';
import UserContext, {isUserContextStillValidInNminutes} from '../domain/UserContext';

import {GlobalStyle, StyledApp, StyledCenter, StyledTopScreenWarning} from './_styled';
import UserContextExpirationObserver from './UserContextExpirationObserver';
import GoogleLoginButton from './commons/GoogleLoginButton';
import envConfig from '../envConfig';
import {detectAuthCb, startLogin} from '../data/service/impl/google/GoogleAuthService';

const App = () => {
  const [userContext, setUserContext] = useRecoilState(userContextAtom);
  const streams = useRecoilValue(streamsAtom);
  const spotlightYear = useRecoilValue(spotlightYearAtom);
  const setBirth = useSetRecoilState(birthAtom);
  const photoAlbumId = useSetRecoilState(photoAlbumIdAtom);

  const actSetStreams = useActionSetStreams();

  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [showLoginWillExpireWarning, setShowLoginWillExpireWarning] = useState(false);

  useEffect(() => {
    detectAuthCb().then((ctxOrNull) => {
      if (ctxOrNull) {
        setIsLoggingIn(true);
        ctxPersistorService.persistContext(ctxOrNull);
        setUserContext(ctxOrNull);
        onLoggedIn(ctxOrNull);

        // "clear" the hash with params from the url
        window.history.pushState({}, '', new URL('/', window.location.origin));
      }
    });
  }, []);

  useEffect(() => {
    // on startup, if we are not logged in, load ctx from storage
    if (!userContext && !isLoggingIn) {
      // if it is still valid (token not expired) for another five minutes, use it.
      // therefore user starts already logged in
      const ctx = ctxPersistorService.loadContext();

      if (isUserContextStillValidInNminutes(ctx, 5)) {
        setUserContext(ctx);
        onLoggedIn(ctx);
      }
    }
  }, []);

  const mustClickLogin = !userContext;
  const isLoggedInAndLoaded = !!(userContext && streams);

  return (
    <React.Fragment>
      {envConfig.recoilDebug && <RecoilDebugObserver />}
      <GlobalStyle />
      <StyledApp>
        {isLoggingIn && <Spinner />}

        {!isLoggingIn && mustClickLogin && (
          <StyledCenter>
            <h2>My Life</h2>
            <GoogleLoginButton onClick={onLoginButtonClick} />
          </StyledCenter>
        )}

        {isLoggedInAndLoaded && (
          <React.Fragment>
            <UserContextExpirationObserver
              nSecondsBeforeExpiry={60}
              onCtxWillShortlyExpire={() => setShowLoginWillExpireWarning(true)}
            />

            <KeyboardShortcutsListener />

            {showLoginWillExpireWarning && (
              <StyledTopScreenWarning>
                <div>
                  You're session is about to expire in one minute... <br /> Save your work and{' '}
                  <GoogleLoginButton onClick={onLoginButtonClick} />
                </div>
              </StyledTopScreenWarning>
            )}

            {spotlightYear < 1 && (
              <React.Fragment>
                <OptionsBar />
                <Life />
                <SideBar />
              </React.Fragment>
            )}

            {spotlightYear > 0 && (
              <React.Fragment>
                <SpotlightOptionsBar />
                <SpotlightYear year={spotlightYear} />
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </StyledApp>
    </React.Fragment>
  );

  function onLoginButtonClick() {
    setUserContext(undefined);
    setIsLoggingIn(true);
    startLogin();
  }

  function onLoggedIn(userContext: UserContext | undefined) {
    if (!userContext) {
      return;
    }

    setIsLoggingIn(true);

    loadLife(userContext.accessToken)
      .then((life) => {
        setIsLoggingIn(false);
        actSetStreams(life.streams);
        setBirth(life.birth);
        photoAlbumId(life.photoAlbumId);
      })
      .catch((err) => console.error(err));
  }
};

export default App;
