import {useMemo} from 'react';
import {differenceInDays} from 'date-fns';
import LifeItem from '../../domain/LifeItem';
import Boundaries from '../../domain/Boundaries';

/**
 *
 */
export const useCalculateStreamItemBarWidth = (item: LifeItem, lastDayInSpan: Date, zoomedOutMode: boolean): number => {
  return useMemo(() => {
    let barWidth;
    if (item.ongoing) {
      barWidth = differenceInDays(lastDayInSpan, item.startDateObj);
    } else {
      barWidth = item.endDateObj ? differenceInDays(item.endDateObj, item.startDateObj) : 0;
    }
    barWidth = barWidth && barWidth > 4 ? barWidth - 4 : 0;
    return zoomedOutMode ? Math.floor(barWidth / 2) : barWidth;
  }, [item, lastDayInSpan, zoomedOutMode]);
};

/**
 *
 */
export const useCalculateStreamItemLeftOffset = (
  item: LifeItem,
  earliestDayInSpan: Date,
  zoomedOutMode: boolean
): number => {
  return useMemo(() => {
    const daysSinceEarliest = differenceInDays(item.startDateObj, earliestDayInSpan);
    return zoomedOutMode ? Math.floor(daysSinceEarliest / 2) : daysSinceEarliest;
  }, [item, earliestDayInSpan, zoomedOutMode]);
};

/**
 *
 */
export const useCalculateStreamsWrapperWidth = (boundaries: Boundaries, zoomedOutMode: boolean): number => {
  return useMemo(() => {
    const days = boundaries.totalDaysInSpan;
    return zoomedOutMode ? Math.floor(days / 2) : days;
  }, [boundaries, zoomedOutMode]);
};

/**
 *
 */
export const useCalculateTimeLineWidth = (boundaries: Boundaries, zoomedOutMode: boolean): number => {
  return useCalculateStreamsWrapperWidth(boundaries, zoomedOutMode); // same width as streamsWrapper
};

/**
 *
 */
export const calculateCurrentHorizontalPosition = (lifeDomElement: HTMLElement, zoomedOutMode: boolean): number => {
  const pos = lifeDomElement.scrollLeft;
  const width = lifeDomElement.clientWidth;
  return zoomedOutMode ? 2 * pos + width : Math.floor(pos + width / 2);
};

/**
 *
 */
export const calculateYearGridColumn = (daysInThisYear: number, zoomedOutMode: boolean): number => {
  if (!zoomedOutMode) {
    return daysInThisYear;
  }

  if (daysInThisYear === 365) {
    return 182;
  } else if (daysInThisYear === 366) {
    return 185;
  } else {
    throw new Error('WHY is there a year with a day-count of ' + daysInThisYear);
  }
};

export const calculateHorizontalPositionOfYear = (
  boundaries: Boundaries,
  zoomedOutMode: boolean,
  year: number
): number => {
  if (year > 0 && year >= boundaries.firstYear) {
    const diff = differenceInDays(new Date(year, 0), boundaries.firstDay);
    return zoomedOutMode ? Math.floor(diff / 2) : diff;
  } else if (year > 0) {
    return 1;
  } else {
    return 0;
  }
};
