import WithId from '../domain/WithId';

/**
 *
 * @param {WithId[]} array of elements (elements that have an "id" property)
 * @param {string} itemIdToMove the id of the element to move within the array
 * @param {number} moveCount  If < 0  decrement index.  if  > 0 increment index
 * @return {WithId[]} Returns a new array with the specified element moved the respective places.
 */
export default function moveElementInArray<T extends WithId>(array: T[], itemIdToMove: string, moveCount: number): T[] {
  const currentPosition = array.findIndex((element) => element.id === itemIdToMove);
  if (currentPosition < 0) {
    throw new Error(`Cannot move given item with id "${itemIdToMove}" - it is not present in given array!`);
  }

  const newPosition = currentPosition + moveCount;
  if (newPosition < 0 || newPosition > array.length - 1) {
    return array;
  }

  const elementToMove = array[currentPosition];
  const modifiedArray: T[] = [...array];
  modifiedArray.splice(currentPosition, 1);
  modifiedArray.splice(newPosition, 0, elementToMove);
  return modifiedArray;
}
