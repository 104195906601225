import {handleFetchResponseErrors} from '../../../fetchUtils';

const googleDriveService = {
  loadStorageFile,
  saveStorageFile
};

export default googleDriveService;

/**
 * Loads the content as string from the file with the given name. If no such file exists on Google Drive, it is created (empty).
 *
 * @public
 */
async function loadStorageFile(accessToken: string, fileName: string): Promise<string> {
  const fileId = await _getOrCreateStorageFile(accessToken, fileName);

  let res = await fetch(`https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`, {
    method: 'GET',
    headers: new Headers({Authorization: 'Bearer ' + accessToken})
  });
  res = await handleFetchResponseErrors(res);

  return res.text();
}

/**
 * https://developers.google.com/drive/api/v3/reference/files/update
 */
async function saveStorageFile(accessToken: string, fileName: string, fileContent: string) {
  const fileId = await _getOrCreateStorageFile(accessToken, fileName);
  const file = new Blob([fileContent], {type: 'text/plain'});
  const metadata = {
    name: fileName,
    mimeType: 'text/plain'
  };

  const form = new FormData();
  form.append('metadata', new Blob([JSON.stringify(metadata)], {type: 'application/json'}));
  form.append('file', file);

  let res = await fetch(
    `https://www.googleapis.com/upload/drive/v3/files/${fileId}?uploadType=multipart&supportsAllDrives=true`,
    {
      method: 'PATCH',
      headers: new Headers({Authorization: 'Bearer ' + accessToken}),
      body: form
    }
  );
  res = await handleFetchResponseErrors(res);
  return res.json();
}

/**
 * Makes sure that the storage file exists.
 * Returns the fileId of the already existing file, or the newly created file.
 */
async function _getOrCreateStorageFile(accessToken: string, fileName: string): Promise<string> {
  const fileId = await _searchExistingStorageFile(accessToken, fileName);
  if (fileId) {
    return fileId;
  } else {
    return _createStorageFile(accessToken, fileName);
  }
}

/**
 * searches for an existing, non-trashed file with the matching fileName.
 * Returned promise resolves to the fileId or to undefined, if no matching file was found.
 *
 * @private
 */
async function _searchExistingStorageFile(accessToken: string, fileName: string): Promise<string | undefined> {
  console.debug(`looking for storage file "${fileName}"`);

  const q = `name='${fileName}'`;
  const fields = `files(name,id,trashed)`;
  let res = await fetch(`https://www.googleapis.com/drive/v3/files?fields=${fields}&q=${encodeURI(q)}`, {
    method: 'GET',
    headers: new Headers({Authorization: 'Bearer ' + accessToken})
  });
  res = await handleFetchResponseErrors(res);
  const val = await res.json();
  if (val && val.files && val.files.length) {
    const matchingFile = val.files.find((f: any) => f.name === fileName && !f.trashed);
    if (matchingFile) {
      return matchingFile.id;
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
}

/**
 * https://developers.google.com/drive/api/v3/reference/files/create
 * If successful, the HTTP response contains a "File" instance, see  https://developers.google.com/drive/api/reference/rest/v3/files#File
 *
 * The returned Promise resolves to the id of the created file
 *
 * @private
 */
async function _createStorageFile(accessToken: string, fileName: string): Promise<string> {
  const fileContent = '';
  const file = new Blob([fileContent], {type: 'text/plain'});
  const metadata = {
    name: fileName,
    mimeType: 'text/plain',
    parents: ['root']
  };

  const form = new FormData();
  form.append('metadata', new Blob([JSON.stringify(metadata)], {type: 'application/json'}));
  form.append('file', file);

  let res = await fetch(
    'https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart&supportsAllDrives=true',
    {
      method: 'POST',
      headers: new Headers({Authorization: 'Bearer ' + accessToken}),
      body: form
    }
  );
  res = await handleFetchResponseErrors(res);

  const fileInstance = await res.json();

  if (!fileInstance.id) {
    console.error(fileInstance);
    throw new Error('unexpected response after creating a file on google drive');
  }
  return fileInstance.id;
}
