import Stream from '../domain/Stream';
import LifeItem from '../domain/LifeItem';

/**
 * Update given array of streams, remove the given item
 */
export const updateStreamsRemoveItem = (streams: Stream[], itemToDelete: LifeItem) => {
  throwIfEmptyStreams(streams);

  return streams.map((stream) => {
    if (stream.id === itemToDelete.stream) {
      const matchingIndex = stream.items.findIndex((item) => item.id === itemToDelete.id);

      if (matchingIndex > -1) {
        const modifiedItems = [...stream.items];
        modifiedItems.splice(matchingIndex, 1);
        return {...stream, items: modifiedItems};
      } else {
        return stream;
      }
    } else {
      return stream;
    }
  });
};

/**
 * Update given array of streams, update ("replace") the matching item with the given modified one
 */
export const updateStreamsWithModifiedItem = (streams: Stream[], modifiedItem: LifeItem): Stream[] => {
  throwIfEmptyStreams(streams);

  let matchingItem = _findMatchingItemInStreams(streams, modifiedItem.id);

  if (!matchingItem) {
    throw new Error(`Given modified item has id "${modifiedItem.id}" which is not present in given array of streams!`);
  }

  if (matchingItem.stream === modifiedItem.stream) {
    // item stays in its stream
    return _updStrmsItemInSameStream(streams, modifiedItem);
  } else {
    // item was moved from one stream to another
    return _updStrmsMoveItemToOtherStream(streams, modifiedItem);
  }
};

const _findMatchingItemInStreams = (streams: Stream[], itemId: string): LifeItem | undefined => {
  let matchingItem: LifeItem | undefined;
  streams.some((stream) => {
    const innerMatchingItem = stream.items.find((item) => item.id === itemId);
    if (innerMatchingItem) {
      matchingItem = innerMatchingItem;
      return true;
    } else {
      return false;
    }
  });
  return matchingItem;
};

const _updStrmsItemInSameStream = (streams: Stream[], modifiedItem: LifeItem): Stream[] =>
  streams.map((stream): Stream => {
    const modifiedItems = stream.items.map((item) => {
      if (item.id === modifiedItem.id) {
        return modifiedItem;
      } else {
        return item;
      }
    });
    return {...stream, items: modifiedItems};
  });

const _updStrmsMoveItemToOtherStream = (streams: Stream[], modifiedItem: LifeItem): Stream[] => {
  // first, let's remove the "old" item in the "old" stream
  let modifiedStreams = streams.map((stream) => {
    const modifiedItems = stream.items.filter((item) => item.id !== modifiedItem.id);
    return {...stream, items: modifiedItems};
  });

  // then add the modified item
  return updateStreamsWithNewItem(modifiedStreams, modifiedItem);
};

/**
 * Update given array of streams, add a new item.
 * if the given item references a stream that does not exist, the item will be added to the first stream.
 */
export const updateStreamsWithNewItem = (streams: Stream[], newItem: LifeItem) => {
  throwIfEmptyStreams(streams);

  const streamIsKnown = !!streams.find((str) => str.id === newItem.stream);
  if (streamIsKnown) {
    return streams.map((stream) => {
      if (stream.id === newItem.stream) {
        const modifiedItems = [...stream.items, newItem];
        return {...stream, items: modifiedItems};
      } else {
        return stream;
      }
    });
  } else {
    const firstStream = streams[0];
    const modifiedItems = [...firstStream.items, {...newItem, stream: firstStream.id}];
    const modifiedStreams = [...streams];
    modifiedStreams[0] = {...firstStream, items: modifiedItems};
    return modifiedStreams;
  }
};

/**
 * Update given array of streams, delete a whole stream (stream to delete must have no items!)
 */
export const updateStreamsDeleteStream = (streams: Stream[], streamIdToDelete: string) => {
  throwIfEmptyStreams(streams);

  const currentPosition = streams.findIndex((stream) => stream.id === streamIdToDelete);
  if (currentPosition < 0) {
    throw new Error(
      `Cannot delete given stream with id "${streamIdToDelete}" - it is not present in given streams array`
    );
  }

  const currentStrObj = streams[currentPosition];
  if (currentStrObj.items && currentStrObj.items.length > 0) {
    throw new Error(
      `Cannot delete given stream with id "${streamIdToDelete}" - it has still ${currentStrObj.items.length} items!`
    );
  }
  const modifiedStreams = [...streams];
  modifiedStreams.splice(currentPosition, 1);
  return modifiedStreams;
};

const throwIfEmptyStreams = (streams: Stream[]) => {
  if (streams.length < 1) {
    throw new Error('Expected non-empty array of streams');
  }
};
