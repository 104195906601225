import React, {useMemo} from 'react';

import {useCalculateMonthsTimeLineWidth} from './spotlightLayouter';

import {StyledYearsGrid, StyledYearsGridColumn} from './_styled';
import {getMonthsInfoForSpotlightYear} from '../../domain/MonthInfo';

interface MonthsGridTypes {
  year: number;
  visible: boolean;
}

const MonthsGrid = ({year, visible}: MonthsGridTypes) => {
  const gridWidth = useCalculateMonthsTimeLineWidth(year);
  const months = useMemo(() => getMonthsInfoForSpotlightYear(year), [year]);

  return (
    <StyledYearsGrid style={{width: `${gridWidth}py`}} $visible={visible}>
      {months.map((m) => (
        <StyledYearsGridColumn style={{width: `${m.daysCount * 10}px`}} key={`month-grid:${m.month}`} />
      ))}
    </StyledYearsGrid>
  );
};

export default MonthsGrid;
