import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';

import {trashedItemsAtom, streamsAtom} from '../atoms';
import {hasUnsavedItemChangesAtom, selectedItemIdAtom} from '../atoms.ui';
import {updateStreamsRemoveItem} from '../../data/streamsUpdateFunctions';
import {updateTrashedPutItem} from '../../data/trashUpdateFunctions';
import {getStreamsOrThrow} from '../selectors';
import {getAllItemsFlat, getMatchingItemOrThrow} from '../../domain/Life';

/**
 * The returned action will trash the respective item (remove from the streams and put it into the trashedItem recoil Atom)
 */
const useActionTrashItem = (): ((itemIdToTrash: string) => void) => {
  const streams = useRecoilValue(getStreamsOrThrow);
  const setStreams = useSetRecoilState(streamsAtom);
  const setSelectedItemId = useSetRecoilState(selectedItemIdAtom);
  const setHasUnsavedChanges = useSetRecoilState(hasUnsavedItemChangesAtom);
  const [trashedItems, setTrashedItems] = useRecoilState(trashedItemsAtom);

  return (itemIdToTrash: string): void => {
    const items = getAllItemsFlat(streams);
    if (items.length < 2) {
      // prevent deletion of last item
      console.info('Not allowing deletion of last item...');
      return;
    }

    setHasUnsavedChanges(false);
    setSelectedItemId(undefined);

    // let's save the item to trash in our recoil state, for potential later recovery
    const itemToDelete = getMatchingItemOrThrow(streams, itemIdToTrash);
    const modifiedTrash = updateTrashedPutItem(trashedItems, itemToDelete);
    setTrashedItems(modifiedTrash);

    // remove the item from the streams
    const modifiedStreams = updateStreamsRemoveItem(streams, itemToDelete);
    setStreams(modifiedStreams);
  };
};

export default useActionTrashItem;
