import React, {useEffect, useState} from 'react';
import PhotoDisplay, {PhotoDisplaySkeleton} from '../commons/PhotoDisplay';
import Button from '../commons/Button';
import googlePhotoPickerService from '../../data/service/impl/google/GooglePhotoPickerService';

import {StyledEditorButtonGroup, StyledWarningText} from './_styled';

const MAX_PICKED_PHOTOS = 9; // since we are duplicating every picked photo, let's just allow a small selection. We can display anyways not more than 9 photos in the sidebar

interface ItemDetailEditPhotosSectionTypes {
  itemId: string;
  accessToken: string;
  photoAlbumId: string;
  onChange: (photoIds: string[], albumId: string) => void;
  photoIds?: string[]; // the photo ids from the item
  itemAlbumId?: string; // the albumId from the item (legacy, can be removed after google picker migration is complete)
}

const ItemDetailEditPhotosSection = ({
  itemId,
  accessToken,
  photoAlbumId,
  onChange,
  photoIds,
  itemAlbumId
}: ItemDetailEditPhotosSectionTypes) => {
  const [isCurrentlyPicking, setIsCurrentlyPicking] = useState<boolean>(false);

  // as soon as the google picker session has items selected, we want to display an updated spinner to the user.
  const [willHaveNItem, setWillHaveNItem] = useState<number>(0);

  const maxNewPhotos = Math.max(MAX_PICKED_PHOTOS - (photoIds || []).length, 0);

  useEffect(() => {
    setIsCurrentlyPicking(false);
  }, [itemId]);

  return (
    <>
      <h4>{'Photos'}</h4>
      {(!photoIds || photoIds.length < 1) && itemAlbumId && (
        <StyledWarningText>This item still has a legacy photo album selected! {itemAlbumId}</StyledWarningText>
      )}

      {photoIds && !isCurrentlyPicking && (
        <div>
          <PhotoDisplay accessToken={accessToken} photoIds={photoIds} onPhotoRemove={onPhotoRemove} />
        </div>
      )}

      {!isCurrentlyPicking && (
        <>
          {maxNewPhotos > 0 && <span>You can pick max {maxNewPhotos} additional photos.</span>}

          <StyledEditorButtonGroup>
            {!!photoIds?.length && (
              <Button onClick={onRemoveAllClicked}>
                <i className="icon-cancel" /> Remove all
              </Button>
            )}

            {maxNewPhotos > 0 && (
              <Button onClick={onStartPickPhotosClicked}>
                <i className="icon-picture" /> Pick photos
              </Button>
            )}
          </StyledEditorButtonGroup>
        </>
      )}

      {isCurrentlyPicking && willHaveNItem < 1 && (
        <>Currently picking {maxNewPhotos} photos from google photos, please switch to the dialog...</>
      )}

      {isCurrentlyPicking && willHaveNItem > 0 && (
        <div>
          <PhotoDisplaySkeleton count={willHaveNItem} withSpinners={true} />
        </div>
      )}
    </>
  );

  function onStartPickPhotosClicked() {
    setIsCurrentlyPicking(true);

    const onItemsSelected = (count: number) => setWillHaveNItem((photoIds || []).length + count);

    googlePhotoPickerService
      .pickPhotos(accessToken, photoAlbumId, maxNewPhotos, onItemsSelected)
      .then(([newPhotoItemIds, ensuredAlbumId]) => {
        setWillHaveNItem(0);
        setIsCurrentlyPicking(false);

        const concatenated = (photoIds || []).concat(newPhotoItemIds);
        onChange(concatenated, ensuredAlbumId);
      });
  }

  function onPhotoRemove(photoId: string) {
    const modifiedPhotos = (photoIds || []).filter((photo) => photo !== photoId);
    onChange(modifiedPhotos, photoAlbumId);
  }

  function onRemoveAllClicked() {
    onChange([], photoAlbumId);
  }
};

export default ItemDetailEditPhotosSection;
