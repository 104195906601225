/**
 *
 * See https://developers.google.com/identity/protocols/oauth2/scopes#drive for a list of scopes (currently missing the new photospicker scopes
 *
 */

const SCOPES = [
  'https://www.googleapis.com/auth/userinfo.email',
  'https://www.googleapis.com/auth/userinfo.profile',

  // needed to store our my-life data as json file to google drive
  'https://www.googleapis.com/auth/drive.file',

  // for documentation of google photo picker scopes as of march 2025 : https://developers.google.com/photos/overview/authorization#picker-api-scopes
  'https://www.googleapis.com/auth/photospicker.mediaitems.readonly',

  //  This is needed for creating a own "my-life" album
  'https://www.googleapis.com/auth/photoslibrary.appendonly',
  'https://www.googleapis.com/auth/photoslibrary.readonly.appcreateddata'
];

export default SCOPES;
