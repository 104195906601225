import {addSeconds} from 'date-fns';

import UserContext from '../../../../domain/UserContext';
import {handleFetchResponseErrors} from '../../fetchUtils';
import SCOPES from './googleAuthScopes';
import {GOOGLE_CLIENT_ID} from './googleClientId';

/**
 *
 * Handles OAuth 2 Authentication against Google Identity Service
 *
 *  ----- See  https://console.cloud.google.com/apis/dashboard?project=my-life-344509
 *  ----- https://github.com/MomenSherif/react-oauth#usegooglelogin-both-implicit--authorization-code-flow
 *
 */

/**
 * https://developers.google.com/identity/protocols/oauth2/javascript-implicit-flow
 *
 * Step 1: Redirect to Google's OAuth 2.0 server
 */

export function startLogin() {
  /*
  This is configured in the google cloud console: https://console.cloud.google.com/apis/credentials?project=my-life-344509
  naviagte to OAuth 2.0 Client IDs  -> my-life-webapp
   */
  const redirectUri = `${window.location.origin}/?authcb`;
  console.log('redirectUri', redirectUri);

  const params = new URLSearchParams();
  params.append('scope', SCOPES.join(' '));
  params.append('include_granted_scopes', 'true');
  params.append('response_type', 'token');
  params.append('redirect_uri', redirectUri);
  params.append('client_id', GOOGLE_CLIENT_ID);
  window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?${params.toString()}`;
}

/**
 * Step 2:  google calls us back with the "redirectUri" -> detect it, and if so, fetch additional infos
 */
export async function detectAuthCb(): Promise<UserContext | null> {
  if (window.location.pathname !== '/' || window.location.search !== '?authcb' || !window.location.hash) {
    return null;
  }

  // pathname matches, let's get some values from the url params
  const params = new URLSearchParams(window.location.hash.substring(1));
  const accessToken = params.get('access_token');
  const expiresIn = Number.parseInt(params.get('expires_in') || '', 10);

  if (!accessToken || !Number.isFinite(expiresIn)) {
    return null;
  }

  // we have an accessToken and "expiresIn", fetch additional info for the google user
  const userInfoResponse = await fetchUserInfo(accessToken);
  return {
    accessToken: accessToken,
    expiresIn: expiresIn,
    expiresAt: addSeconds(new Date(), expiresIn).getTime(),
    userId: userInfoResponse.sub,
    email: userInfoResponse.email,
    name: userInfoResponse.name,
    profileImg: userInfoResponse.picture
  };
}

async function fetchUserInfo(accessToken: string) {
  let res = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
    headers: new Headers({Authorization: 'Bearer ' + accessToken})
  });
  res = await handleFetchResponseErrors(res);
  return await res.json();
}

/**
 * tokenResponse  that is returned from "useGoogleLogin" when successful
 */
/*
 {
  "access_token": "ya29.a0Ad52N39f3B99LVziBGgNyHKGRjqyq4Z5Nip_FfCplxhak7Ou80uI9lBmAd2faOSf48-D_20gmsvmoquIC1************************",
  "token_type": "Bearer",
  "expires_in": 3599,
  "scope": "email profile https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/photoslibrary.readonly openid https://www.googleapis.com/auth/drive.file",
  "authuser": "0",
  "prompt": "none"
}
*/

/**
 * userInfoResponse  that is returned from a GET call to 'https://www.googleapis.com/oauth2/v3/userinfo'
 */

/*
{
    "sub": "103949975110131559430",
    "name": "Sergio Trentini",
    "given_name": "Sergio",
    "family_name": "Trentini",
    "picture": "https://lh3.googleusercontent.com/a/AEdFTp7GkkXamoLP5xn4ykF1U60ICxv1b_x9-qeCq6lS4Q=s96-c",
    "email": "xeronimus@gmail.com",
    "email_verified": true,
    "locale": "en"
}
 */
