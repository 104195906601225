import {useRecoilValue, useSetRecoilState} from 'recoil';

import {hasUnsavedItemChangesAtom, selectedItemIdAtom, SIDEBAR_CONTENT, sideBarContentAtom} from '../atoms.ui';

/**
 * returned action will select the item with the given id and set sidebar content.
 * will not change state, if flag "hasUnsavedChanges" is true.
 */
const useActionSelectItem = (): ((itemId: string | undefined) => void) => {
  const setSelectedItemId = useSetRecoilState(selectedItemIdAtom);
  const setSideBarContentToDisplay = useSetRecoilState(sideBarContentAtom);
  const hasUnsavedChanges = useRecoilValue(hasUnsavedItemChangesAtom);

  return (itemId: string | undefined) => {
    if (hasUnsavedChanges) {
      return;
    }

    setSelectedItemId(itemId);
    setSideBarContentToDisplay(SIDEBAR_CONTENT.ITEM);
  };
};

export default useActionSelectItem;
