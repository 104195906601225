import {isValid, isBefore, set} from 'date-fns';

import Life from '../../domain/Life';
import LifeItem from '../../domain/LifeItem';
import Stream from '../../domain/Stream';
import StreamsGrouped from '../../domain/StreamsGrouped';
import StreamDescription from '../../domain/StreamDescription';
import {toTsSeconds} from '../timeUtil';
import PersistedLife from './PersistedLife';
import PersistedItem from './PersistedItem';
import GoogleDriveStorageService from './impl/google/drive/GoogleDriveStorageService';

const {loadPersistedLife, savePersistedLife} = GoogleDriveStorageService;

/**
 *
 */
export async function loadLife(accessToken: string): Promise<Life> {
  let life = await loadPersistedLife(accessToken);

  life = addBirthToPersistedLifeIfNotPresent(life);

  validateObjectAsPersistedLife(life);

  const augmentedItems = convertFromPersistedItemsToLifeItems(life.items);
  const streamsWithItems = groupItemsByStream(life.streams, augmentedItems);
  return {birth: sanitizeBirth(life.birth), streams: streamsWithItems, photoAlbumId: life.photoAlbumId || ''};
}

function addBirthToPersistedLifeIfNotPresent(life: any): any {
  if (!life.birth) {
    life.birth = toTsSeconds(new Date(2000, 0, 1));
  }
  return life;
}

/**
 *
 */
function validateObjectAsPersistedLife(life: any) {
  if (!life.birth || !Number.isSafeInteger(life.birth)) {
    throw new Error('life must contain a "birth" property that is a integer!');
  }

  if (!life.items) {
    throw new Error('life must contain a "items" property');
  }

  if (!Array.isArray(life.items)) {
    throw new Error('"items" property must be an array');
  }

  if (!life.streams) {
    throw new Error('life must contain a "streams" property');
  }

  if (!Array.isArray(life.streams)) {
    throw new Error('"streams" property must be an array');
  }
  if (life.streams.length < 1) {
    throw new Error('"streams" array must at least contain one stream');
  }

  life.items.forEach(validateObjectAsPersistedLifeItem);

  throwIfNonUniqueIds(life.items);

  const streamIds = life.streams.map((s: any) => s.id);
  if (!life.items.every((item: any) => streamIds.includes(item.stream))) {
    throw new Error('every item must reference a known "stream"');
  }
}

export type StringToBooleaMap = {
  [id: string]: boolean;
};

function throwIfNonUniqueIds(items: any[]) {
  const map: StringToBooleaMap = {};

  items.forEach((it) => {
    if (map[it.id]) {
      throw new Error(`Item id duplicate error! Item with id "${it.id}" already exists!`);
    }
    map[it.id] = true;
  });
}

function validateObjectAsPersistedLifeItem(lifeItem: any) {
  if (!lifeItem.id) {
    throw new Error(`lifeItem must have a "id" property `);
  }

  if (!lifeItem.title) {
    throw new Error(`lifeItem must contain a non-empty "title" property id=${lifeItem.id}`);
  }

  if (lifeItem.startDate === null || lifeItem.startDate === undefined) {
    throw new Error(`lifeItem must contain a "startDate" property id=${lifeItem.id}`);
  }

  const parsedStartDate = parseDateFromSeconds(lifeItem.startDate);
  if (!isValid(parsedStartDate)) {
    throw new Error('lifeItem must contain a valid "startDate" value (a number, seconds since 1970)');
  }

  if (lifeItem.endDate !== null && lifeItem.endDate !== undefined) {
    const parsedEndDate = parseDateFromSeconds(lifeItem.endDate);
    if (!isValid(parsedEndDate)) {
      throw new Error('if lifeItem specifies "endDate" property, it must be valid (a number, seconds since 1970)');
    }
    const dayStartDate = clearTime(parsedStartDate);
    const dayEndDate = clearTime(parsedEndDate);
    if (!isBefore(dayStartDate, dayEndDate)) {
      throw new Error(`startDate must be before endDate  id=${lifeItem.id}`);
    }

    if (lifeItem.ongoing) {
      throw new Error('if lifeItem specifies "endDate" property, it cannot also be "ongoing"');
    }
  }
}

function sanitizeBirth(birthPersisted: number): Date {
  return clearTime(parseDateFromSeconds(birthPersisted));
}

const clearTime = (dateObject: Date) =>
  set(dateObject, {
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0
  });

const parseDateFromSeconds = (secondsEpoch: number) => {
  if (!Number.isInteger(secondsEpoch)) {
    throw new Error('We expect an integer');
  }
  return new Date(secondsEpoch * 1000);
};

export const convertFromPersistedItemsToLifeItems = (persistedItems: PersistedItem[]): LifeItem[] =>
  persistedItems.map(convertSinglePersistedItemToLifeItem);

const convertSinglePersistedItemToLifeItem = (item: PersistedItem): LifeItem => {
  // specifically pick only the properties that we want. do not just take everything - we don't know what's in that json file...
  const lifeItem: LifeItem = {
    id: item.id,
    title: item.title,
    description: item.description,
    stream: item.stream,
    startDateObj: new Date(item.startDate * 1000),
    urls: item.urls && item.urls.length > 0 ? item.urls : []
  };

  if (item.endDate !== undefined && item.endDate !== null) {
    lifeItem.endDateObj = new Date(item.endDate * 1000);
  } else if (item.ongoing) {
    lifeItem.ongoing = true;
  }

  if (item.album) {
    // legacy
    lifeItem.album = item.album;
  }

  if (item.photo) {
    // legacy
    lifeItem.photo = item.photo;
  }

  if (item.photos) {
    lifeItem.photos = item.photos;
  } else if (item.photo) {
    lifeItem.photos = [item.photo];
  }

  if (item.place) {
    lifeItem.place = item.place;
  }

  return lifeItem;
};

function groupItemsByStream(streams: StreamDescription[], items: LifeItem[]): Stream[] {
  const streamsById = streams.reduce((total: StreamsGrouped, currentStream) => {
    total[currentStream.id] = {...currentStream, items: []};
    return total;
  }, {});

  items.forEach((item) => {
    if (!streamsById[item.stream]) {
      throw new Error(`item references unknown stream ${item.stream}`);
    }
    streamsById[item.stream].items.push(item);
  }, {});

  return Object.values(streamsById);
}

export async function saveLife(accessToken: string, streams: Stream[], birth: Date, photoAlbumId: string) {
  const pLife = convertToPersisted(streams, birth, photoAlbumId);
  return savePersistedLife(accessToken, pLife);
}

/**
 * Take our app-state streams array (streams with respective items) and restructure them for persistent storage.
 */
export function convertToPersisted(streams: Stream[], birth: Date, photoAlbumId: string): PersistedLife {
  const flatLifeItems: LifeItem[] = streams.reduce(
    (allItems: LifeItem[], currentStream) => allItems.concat(currentStream.items),
    []
  );

  const persistedItems: PersistedItem[] = flatLifeItems.map((itm: LifeItem) => {
    const pItem: PersistedItem = {
      id: itm.id,
      startDate: toTsSeconds(itm.startDateObj),
      title: itm.title,
      description: itm.description,
      stream: itm.stream
    };
    if (itm.endDateObj) {
      pItem.endDate = toTsSeconds(itm.endDateObj);
    } else if (itm.ongoing) {
      pItem.ongoing = true;
    }

    if (itm.urls.length > 0) {
      pItem.urls = itm.urls;
    }

    if (itm.album) {
      // legacy
      pItem.album = itm.album;
    }

    if (itm.photo) {
      // legacy
      pItem.photo = itm.photo;
    }

    if (itm.photos) {
      pItem.photos = itm.photos;
    }

    if (itm.place) {
      pItem.place = itm.place;
    }

    return pItem;
  });

  const streamDescriptions: StreamDescription[] = streams.map((stream) => ({
    id: stream.id,
    title: stream.title,
    color: stream.color
  }));

  return {streams: streamDescriptions, items: persistedItems, birth: toTsSeconds(birth), photoAlbumId};
}
