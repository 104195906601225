import React from 'react';

import {calculateYearGridColumn, useCalculateTimeLineWidth} from './layouter';

import {StyledYearsGrid, StyledYearsGridColumn} from './_styled';
import Boundaries from '../../domain/Boundaries';

interface YearGridTypes {
  boundaries: Boundaries;
  visible: boolean;
  zoomedOutMode: boolean;
}

export const YearGrid = ({boundaries, visible, zoomedOutMode}: YearGridTypes) => {
  const gridWidth = useCalculateTimeLineWidth(boundaries, zoomedOutMode);

  return (
    <StyledYearsGrid style={{width: `${gridWidth}px`}} $visible={visible} x-component-name="StyledYearsGrid">
      {boundaries.years.map((y) => (
        <StyledYearsGridColumn
          style={{width: `${calculateYearGridColumn(y.daysInYear, zoomedOutMode)}px`}}
          key={`year-grid:${y.year}`}
        />
      ))}
    </StyledYearsGrid>
  );
};
