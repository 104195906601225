import {useCallback, useEffect} from 'react';
import {useRecoilValue, useSetRecoilState} from 'recoil';

import {birthAtom, photoAlbumIdAtom, streamsAtom} from './atoms';
import {userContextAtom} from './atoms.user';
import {saveLife} from '../data/service/lifeService';
import {currentlySavingAtom, SIDEBAR_CONTENT, sideBarContentAtom} from './atoms.ui';
import usePrevious from '../components/commons/usePrevious';
import Stream from '../domain/Stream';

/**
 * This (null-) component subscribes to the "streamsAtom" (recoil) and persists the streams (i.e. the Life) to storage when the streams change.
 *
 */
export default function LifePersistorObserver() {
  const setCurrentlySaving = useSetRecoilState(currentlySavingAtom);
  const setSideBarContent = useSetRecoilState(sideBarContentAtom);

  const streams = useRecoilValue(streamsAtom);
  const prevStreams = usePrevious<Stream[] | undefined>(streams);

  const photoAlbumId = useRecoilValue(photoAlbumIdAtom);
  // const prevPhotoAlbumId = usePrevious<string>(photoAlbumId);

  const birth = useRecoilValue(birthAtom);
  const userContext = useRecoilValue(userContextAtom);

  const persistStreams = useCallback(() => {
    const hasStreamsAndPreviousStreamsWasDefined = !!(streams && prevStreams);
    const streamsChanged = prevStreams !== streams;

    // const photoAlbumIdChanged = photoAlbumId && prevPhotoAlbumId !== photoAlbumId;
    if (userContext?.accessToken && hasStreamsAndPreviousStreamsWasDefined && streamsChanged) {
      setCurrentlySaving(true);
      setTimeout(async () => {
        try {
          console.debug('saving life with photoAlbumId', photoAlbumId);
          await saveLife(userContext.accessToken, streams, birth, photoAlbumId);
        } catch (e) {
          console.error(e);
          setSideBarContent(SIDEBAR_CONTENT.ERROR);
        }
        setCurrentlySaving(false);
      }, 1);
    }
  }, [streams, prevStreams, userContext, birth, setCurrentlySaving, setSideBarContent]);

  useEffect(() => void persistStreams(), [persistStreams]);

  return null;
}
