import LifeItem from '../domain/LifeItem';

export const updateTrashedPutItem = (trashed: LifeItem[], itemToPut: LifeItem): LifeItem[] => {
  return [...trashed, itemToPut];
};

export const updateTrashedRemoveItem = (trashed: LifeItem[], itemToRemove: LifeItem): LifeItem[] => {
  const itemIndex = trashed.findIndex((ti) => ti.id === itemToRemove.id);
  if (itemIndex < 0) {
    throw new Error(
      `We cannot take item with id "${itemToRemove.id}" out of the trash. It was not found in the trash!`
    );
  }
  const modifiedTrashedItems = [...trashed];
  modifiedTrashedItems.splice(itemIndex, 1);
  return modifiedTrashedItems;
};
