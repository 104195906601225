import styled from 'styled-components';
import tinycolor from 'tinycolor2';

import {FONT_GREY, FONT_LIGHT_BORDER_GREY, HIGHLIGHTING, WARNING} from '../colors';
import {StyledPullThemRight} from '../commons/_styled';

export const StyledSideBar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;

  overflow-y: auto;
  overflow-x: hidden;

  padding: 16px;
  width: 500px;
  flex-shrink: 0;

  border-left: 1px solid ${FONT_LIGHT_BORDER_GREY};
  box-shadow: -6px 1px 9px 0 rgba(0, 0, 0, 0.09);
  z-index: 30000;
`;

export const StyledSideBarPart = styled.div`
  border-bottom: 1px solid ${FONT_LIGHT_BORDER_GREY};
  padding-bottom: 24px;

  &:last-child {
    border-bottom: none;
  }
`;

export const StyledSideBarTopMenu = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const StyledStreamSelectContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  cursor: pointer;
  width: 100%;
`;

export const StyledItemDetail = styled.div`
  margin-top: 24px;
  border-top: 4px solid ${(props) => props.color};
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const StyledOngoingAndAgeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StyledDateRange = styled(StyledPullThemRight)`
  font-size: 75%;
`;

export const StyledDateInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;

  > * {
    width: 30%;
  }

  input {
    display: block;
    width: 90%;
    border: none;
    background: transparent;
    outline: none;
    margin-bottom: 8px;
    margin-top: 8px;
    border-bottom: 1px solid ${FONT_LIGHT_BORDER_GREY};

    &:hover {
      border-bottom: 1px solid ${FONT_GREY};
    }

    &:active,
    &:focus {
      border-bottom: 1px solid ${HIGHLIGHTING};
    }

    &.warning {
      color: ${WARNING};
      border-bottom: 1px solid ${WARNING};

      &:hover,
      &:active,
      &:focus {
        border-bottom: 1px solid ${WARNING};
      }
    }
  }
`;

export const StyledStreamsEditor = styled.div``;

export const StyledColorPickerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const StyledColorPickerItemPreview = styled.div.attrs((props) => ({
  style: {
    background: `#${tinycolor(props.color).lighten(39).toHex()}`,
    color: tinycolor(props.color).lighten(39).isDark() ? '#fff' : '#444',
    borderColor: props.color
  }
}))`
  margin: 0 20px;
  width: 30%;
  border-radius: 0 8px 8px 0;
  border-left: 2px solid transparent;
  padding: 8px;

  > span {
    font-weight: 600;
  }
`;

export const StyledStreamEditor = styled.div.attrs((props) => ({
  style: {
    borderTop: `4px solid ${props.color}`
  }
}))`
  margin-top: 24px;
`;

interface StyledStreamProps {
  $selected: boolean;
}

export const StyledStream = styled.div<StyledStreamProps>`
  cursor: pointer;
  padding: 4px;
  position: relative;

  background: ${(props) => (props.$selected ? HIGHLIGHTING : 'transparent')};
  color: ${FONT_GREY};

  &:hover {
    background: ${(props) => (props.$selected ? HIGHLIGHTING : `#${tinycolor(HIGHLIGHTING).setAlpha(0.1).toHex()}`)};
  }

  &:after {
    content: ' ';
    width: 16px;
    height: 16px;
    position: absolute;
    right: 4px;
    background: ${(props) => props.color};
    border-radius: 50%;
    border: 2px solid white;
  }
`;

export const StyledStreamsSelector = styled.div`
  border-radius: 2px;
  border: 1px solid ${FONT_LIGHT_BORDER_GREY};
`;

export const StyledEditorButtonGroup = styled(StyledPullThemRight)`
  margin-top: 24px;

  > button {
    margin-right: 4px;
  }
`;

export const StyledStatistics = styled.div``;

export const StyledBirthSelector = styled.div``;

export const StyledUserProfile = styled.div`
  img {
    width: 56px;
    border-radius: 50%;
  }
`;

export const StyledSaveErrorAndExport = styled.div`
  h3 {
    color: #ff8100;
  }
`;

export const StyledMapWrapper = styled.div`
  margin-top: 24px;
`;

export const StyledMapLocationSearchControls = styled.div`
  display: flex;
  flex-direction: row;

  > *:first-child {
    flex-grow: 1;
  }
`;

export const StyledBelowMapInfos = styled.div`
  margin-top: 24px;

  > div {
    margin-bottom: 12px;
  }
`;

export const StyledTrashBinItemList = styled.div``;
export const StyledTrashBinItemListElement = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 4px 0;

  > span {
    width: 29%;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const StyledHelp = styled.div`
  h3 {
  }
`;

export const StyledShortcutHelp = styled.div`
  > div {
    display: flex;
    flex-direction: row;
    align-items: start;
    margin: 16px 0;
  }
`;

export const StyledShortcutKey = styled.div`
  width: 30%;
  flex-shrink: 0;

  > span {
    display: inline-block;
    padding: 3px 5px;
    font-family: ui-monospace, SFMono-Regular, 'SF Mono', Menlo, Consolas, 'Liberation Mono', monospace;
    line-height: 10px;
    vertical-align: middle;
    background-color: rgb(246, 248, 250);
    border: 1px solid rgba(175, 184, 193, 0.2);
    border-radius: 6px;
    box-shadow: inset 0 -1px 0 rgba(175, 184, 193, 0.2);
  }
`;

export const StyledShortcutKeyAction = styled.div`
  flex-grow: 0;
`;

export const StyledUrlsEditList = styled.ul`
  list-style-type: none;
  margin: 0 0 12px 0;
  padding: 0;

  li {
    list-style-type: none;
    margin: 0;
    padding: 0;

    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export const StyledBuildInfo = styled.div`
  margin-top: 20px;
`;

export const StyledWarningText = styled.div`
  background: ${WARNING};
  border-radius: 4px;
  margin: 8px;
  padding: 8px;
  color: white;
`;
