import {handleFetchResponseErrors} from '../../fetchUtils';
import PhotoAlbumService from '../../PhotoAlbumService';
import AlbumInfo from '../../AlbumInfo';
import PhotoItem from '../../../../domain/PhotoItem';
import uuid from '../../../uuid';

const MY_LIFE_ALBUM_TITLE = '_my-life';

const googlePhotosAlbumService: PhotoAlbumService = {
  ensureOwnAlbum,
  addPhotosToOwnAlbum
};

export default googlePhotosAlbumService;

const PHOTO_COPY_SIZE = 'w256-h256-c';

/**
 * With a change in google photos API as of May 2025, a webapplication like my-life no longer can access all the images in the users photo library.
 * (see this page for details about the API change https://developers.google.com/photos/support/updates)
 *
 * "You can now only list, search, and retrieve albums and media items that were created by your app."
 * This means, we have to let the user pick some photos with the new picker API, re-upload these picked photos and put them in an own google photo album.
 *
 * This method takes the picked photoItems, downloads a small, square version of each photo, re-uploads them and creates new google mediaItems.
 * Those new items are added to the own my-life album.
 *
 * For documentation of the library API, see https://developers.google.com/photos/library/guides/get-started-library
 *
 * @param accessToken the google access token
 * @param albumId the id of our own google album. Will throw, if no such album exists!
 * @param photoItems
 */
async function addPhotosToOwnAlbum(accessToken: string, albumId: string, photoItems: PhotoItem[]): Promise<string[]> {
  if (photoItems.length < 1 || photoItems.length > 50) {
    throw new Error('photoItems must be non-empty list with not more than 50 items');
  }

  const uploadTokenPromises: Promise<string | null>[] = photoItems.map((i) => downloadAndUploadCopy(accessToken, i));
  const uploadTokens = sift(await Promise.all(uploadTokenPromises));

  const batchCreatePayload = {
    albumId,
    newMediaItems: uploadTokens.map((tkn) => ({
      description: 'my-life-photo-item',
      simpleMediaItem: {
        fileName: uuid(),
        uploadToken: tkn
      }
    }))
  };

  const res = await fetch(`https://photoslibrary.googleapis.com/v1/mediaItems:batchCreate`, {
    method: 'POST',
    headers: new Headers({Authorization: 'Bearer ' + accessToken, 'Content-type': 'application/json'}),
    body: JSON.stringify(batchCreatePayload)
  });

  await handleFetchResponseErrors(res);

  const body = await res.json(); // body contains "newMediaItemResults" as array
  // see also https://developers.google.com/photos/library/guides/upload-media

  if (body?.newMediaItemResults && Array.isArray(body?.newMediaItemResults)) {
    return body.newMediaItemResults
      .map((nI: any) => {
        if (nI.status?.code || !nI.mediaItem) {
          console.warn('at least one upload failed', nI.status?.code, nI.uploadToken);
          return null;
        } else {
          return nI.mediaItem.id;
        }
      })
      .filter(Boolean) as NonNullable<string>[];
  } else {
    console.error('unexpected response body structure!');
    return Promise.resolve([]);
  }
}

async function downloadAndUploadCopy(accessToken: string, item: PhotoItem): Promise<string | null> {
  const buf = await downloadPhotoBytes(accessToken, item);
  return uploadPhotoBytes(accessToken, buf);
}

async function downloadPhotoBytes(accessToken: string, item: PhotoItem): Promise<ArrayBuffer> {
  const imgUrl = `${item.baseUrl}=${PHOTO_COPY_SIZE}`; /// we fetch a smaller version of the original photo to upload as a copy to our my-life album!
  return (
    await fetch(imgUrl, {
      method: 'GET',
      headers: new Headers({
        Authorization: 'Bearer ' + accessToken
      })
    })
  ).arrayBuffer();
}

async function uploadPhotoBytes(accessToken: string, photoBytes: ArrayBuffer): Promise<string | null> {
  const res = await fetch('https://photoslibrary.googleapis.com/v1/uploads', {
    method: 'POST',
    headers: new Headers({
      Authorization: 'Bearer ' + accessToken,
      'Content-type': 'application/octet-stream',
      'X-Goog-Upload-Content-Type': 'image/jpeg',
      'X-Goog-Upload-Protocol': 'raw'
    }),
    body: photoBytes
  });

  if (res.ok) {
    console.debug('uploaded photo bytes and got uploadToken...');
    return res.text();
  } else {
    console.error('Unable to upload Photo');
    return null;
  }
}

async function ensureOwnAlbum(accessToken: string, albumId: string): Promise<string> {
  try {
    const album = await getOwnAlbum(accessToken, albumId);
    return album.id;
  } catch (error) {
    const newAlbum = await createOwnAlbum(accessToken);
    return newAlbum.id;
  }
}

/**
 *
 */
async function getOwnAlbum(accessToken: string, albumId: string): Promise<AlbumInfo> {
  console.debug(`looking for album with id ${albumId}`);

  if (!albumId) {
    throw new Error('No albumId provided');
  }

  const res = await fetch(`https://photoslibrary.googleapis.com/v1/albums/${albumId}`, {
    method: 'GET',
    headers: new Headers({Authorization: 'Bearer ' + accessToken, 'Content-type': 'application/json'})
  });

  await handleFetchResponseErrors(res);

  const body = await res.json();

  return {
    id: albumId,
    title: body.title,
    url: body.productUrl
  };
}

async function createOwnAlbum(accessToken: string): Promise<AlbumInfo> {
  const res = await fetch(`https://photoslibrary.googleapis.com/v1/albums`, {
    method: 'POST',
    headers: new Headers({Authorization: 'Bearer ' + accessToken, 'Content-type': 'application/json'}),
    body: JSON.stringify({
      album: {
        title: MY_LIFE_ALBUM_TITLE
      }
    })
  });

  await handleFetchResponseErrors(res);

  const body = await res.json();

  console.debug(`Created album "${MY_LIFE_ALBUM_TITLE}" with id "${body.id}" at "${body.productUrl}"`);

  return {
    id: body.id,
    url: body.productUrl,
    title: body.productTitle
  };
}

const sift = <T>(arr: readonly T[]) => arr.filter(Boolean) as NonNullable<T>[];
