import {Loader} from '@googlemaps/js-api-loader';

import Map from '../../Map';
import MapService from '../../MapService';
import LatLng from '../../../../domain/LatLng';
import Place from '../../../../domain/Place';

const googleMapService: MapService = {
  initializeMapComponent,
  geocode,
  reverseGeocode
};
export default googleMapService;

// https://console.cloud.google.com/
const APIKEY = 'AIzaSyAjkd6dlSRkigFJ9o-KI1p_8ZBpV4-5sRc'; // restricted to maps and geocode API

/**
 * creates a new Google Map instance and mounts it on the given domElement.
 *
 * https://developers.google.com/maps/documentation/javascript/examples/map-simple
 */
async function initializeMapComponent(
  domElement: HTMLDivElement,
  center: LatLng,
  onMarkerDragged: (location: LatLng) => void
): Promise<Map> {
  console.log('initializing a google map');
  const loader = new Loader({
    apiKey: APIKEY,
    version: 'weekly'
  });

  const {Map} = await loader.importLibrary('maps');
  const {AdvancedMarkerElement} = await loader.importLibrary('marker');

  const mapInstance = new Map(domElement, {
    center,
    zoom: 7,
    fullscreenControl: false,
    streetViewControl: false,
    mapId: 'my-life-map'
  });

  const marker = new AdvancedMarkerElement({
    map: mapInstance,
    position: center,
    gmpDraggable: true
  });

  marker.addListener('dragend', () => {
    const position = marker.position;
    if (position) {
      const posAsLatLng = position as LatLng;
      onMarkerDragged({
        lat: posAsLatLng.lat,
        lng: posAsLatLng.lng
      });
    }
  });

  return {
    mapInstance,
    marker,
    zoomTo: (pos) => {
      mapInstance.setCenter(pos);
      mapInstance.setZoom(14);
      marker.position = pos;
    }
  };
}

/**
 * Geocode a given query (address/query to a place).
 * See https://developers.google.com/maps/documentation/geocoding/start
 */
async function geocode(query: string): Promise<Place | undefined> {
  const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${query}&key=${APIKEY}`);
  const jsonResponse = await response.json();

  if (jsonResponse?.status === 'OK' && jsonResponse?.results?.length) {
    return {
      location: jsonResponse.results[0].geometry.location,
      name: jsonResponse.results[0].formatted_address
    };
  } else {
    return undefined;
  }
}

/**
 * Reverse geocode a given location   (coordinates to "place")
 * understanding the reverse-geocoding response: https://developers.google.com/maps/documentation/geocoding/requests-reverse-geocoding
 */
async function reverseGeocode(location: LatLng): Promise<string | undefined> {
  const response = await fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${location.lat},${location.lng}&result_type=postal_code|political|country&key=${APIKEY}`
  );
  const jsonResponse = await response.json();

  if (jsonResponse?.status === 'OK' && jsonResponse?.results?.length) {
    return jsonResponse.results[0].formatted_address; // results is already ordered from "most-specific" to "less-specific" -> we can pick the first one
  } else {
    return undefined;
  }
}
