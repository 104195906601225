import {addDays} from 'date-fns';

import uuid from '../data/uuid';
import Place from './Place';

/**
 * The main entity "LifeItem", which stands for an event that happened in your life.
 * Such an event can span over multiple days. A LifeItem belongs to exactly one stream.
 */
export default interface LifeItem {
  id: string;
  startDateObj: Date;
  endDateObj?: Date;
  ongoing?: boolean; // if set to true, this item has a start date, and is still "ongoing". meaning this item is a period that did not yet end.
  title: string;
  description: string;
  urls: string[]; // a list of urls that point to some related external resources
  stream: string; // referencing one of the streams by its id
  album?: string; // LEGACY: this is kept, so that we can display to users that they might need to pick photos with the new google picker. referencing an album by its id, if any
  photo?: string; // LEGACY: this is kept, so that we can display to users that they might need to pick photos with the new google picker. referencing a single photo by its id, if any (cannot use baseUrl directly, since it expires after 60 minutes)
  photos?: string[]; // new as of 2025: list of picked photo ids
  place?: Place; // referencing a place in the world (coordinates and name of place), if any
}

/**
 * creates a new item, with the date set to "firstDay plus delta"  (itemSTartDaysDelta after the firstDay of the stream/life)
 */
export const createNewItem = (firstDay: Date, itemStartDaysDelta: number, streamId: string): LifeItem => {
  const startDateObj = addDays(firstDay, Math.floor(itemStartDaysDelta));
  return newItem(streamId, startDateObj);
};

const newItem = (streamId: string, startDateObj: Date): LifeItem => ({
  id: uuid(),
  description: '',
  startDateObj,
  stream: streamId,
  title: 'Title',
  urls: []
});
